import apiClient from './api-client';

export const updateUser = ( { payload } ) => {
    const { id, data } = payload;
    return apiClient.put(`v1/users/${id}`, data)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updateUserProfile = ( { payload } ) => {
    const { id, data } = payload;
    return apiClient.put(`v1/users/${id}/profile`, data)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updateUserProfileLink = (payload) => {
    const { id, data } = payload;
    return apiClient.put(`v1/users/${id}/profileLink`, data)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getUser = ( id ) => {
    return apiClient.get(`v1/users/${id}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchAuthors = (params) => {
    return apiClient.get(`v1/users/authors`, { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getAuthor = ( id ) => {
    return apiClient.get(`v1/users/authors/${id}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const changePassword = (payload) => {
    return apiClient.put(`v1/users/me/change-password`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const uploadUserImage = (payload) => {
    return apiClient.put(`v1/users/me/media`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then( response => {
        if(response){
            return response.data
        }
        return false
    })
}

export const fetchMembers = (params) => {
    return apiClient.get(`v1/users/members`, { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchMePoints = (params) => {
    return apiClient.get(`v1/users/me/points`, { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}