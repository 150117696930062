import { all, takeEvery, put, call } from 'redux-saga/effects'

import { 
    FETCH_DESIGNATIONS_REQUEST,
    FETCH_DESIGNATIONS_SUCCESS,
    FETCH_DESIGNATIONS_FAILURE,
    FETCH_INDUSTRIES_REQUEST,
    FETCH_INDUSTRIES_SUCCESS,
    FETCH_INDUSTRIES_FAILURE,
    FETCH_COMPANIES_REQUEST,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILURE,
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_SITE_OPTIONS_REQUEST,
    FETCH_SITE_OPTIONS_SUCCESS,
    FETCH_SITE_OPTIONS_FAILURE,
    SEARCH_USER_QUERY_REQUEST,
    SEARCH_USER_QUERY_SUCCESS,
    SEARCH_USER_QUERY_FAILURE
} from './types';

import { fetchDesignations, fetchIndustries, fetchCompanies, fetchCategories, fetchSiteOptions, searchUserQuery } from '../../services/dataService';

export function* fetchDesignationsSaga(){
    try {
        const result = yield call(fetchDesignations)
        if(result){
            yield put({ type: FETCH_DESIGNATIONS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_DESIGNATIONS_FAILURE, payload: error })
    }
}

export function* fetchIndustriesSaga(){
    try {
        const result = yield call(fetchIndustries)
        if(result){
            yield put({ type: FETCH_INDUSTRIES_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_INDUSTRIES_FAILURE, payload: error })
    }
}

export function* fetchCompaniesSaga(){
    try {
        const result = yield call(fetchCompanies)
        if(result){
            yield put({ type: FETCH_COMPANIES_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_COMPANIES_FAILURE, payload: error })
    }
}

export function* fetchCategoriesSaga({payload}){
    try {
        const result = yield call(fetchCategories, payload)
        if(result){
            yield put({ type: FETCH_CATEGORIES_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_CATEGORIES_FAILURE, payload: error })
    }
}

export function* fetchSiteOptionsSaga(){
    try {
        const result = yield call(fetchSiteOptions)
        if(result){
            yield put({ type: FETCH_SITE_OPTIONS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_SITE_OPTIONS_FAILURE, payload: error })
    }
}

export function* searchUserQuerySaga({ payload }){
    console.log('payload', payload)
    try {
        
        const result = yield call(searchUserQuery, payload)
        if(result){
            yield put({ type: SEARCH_USER_QUERY_SUCCESS, payload: result })
        }
    } catch (error) {
        console.log("DX", error)
        yield put({ type: SEARCH_USER_QUERY_FAILURE, payload: error })
    }
}

export default function* collectionSaga(){
    yield all([
        takeEvery(FETCH_DESIGNATIONS_REQUEST, fetchDesignationsSaga),
        takeEvery(FETCH_INDUSTRIES_REQUEST, fetchIndustriesSaga),
        takeEvery(FETCH_COMPANIES_REQUEST, fetchCompaniesSaga),
        takeEvery(FETCH_CATEGORIES_REQUEST, fetchCategoriesSaga),
        takeEvery(FETCH_SITE_OPTIONS_REQUEST, fetchSiteOptionsSaga),
        takeEvery(SEARCH_USER_QUERY_REQUEST, searchUserQuerySaga),
        fetchSiteOptionsSaga()
    ])
}