import { all, takeEvery, put, call } from 'redux-saga/effects'

import {
    GET_POST_REQUEST,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    FETCH_POSTS_REQUEST,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
    POST_FILTER_UPDATE,
    SEARCH_POSTS_REQUEST,
    SEARCH_POSTS_SUCCESS,
    SEARCH_POSTS_FAILURE,
    GET_POST_BY_SLUG_REQUEST,
    CREATE_POST_ACTIVITY_REQUEST,
    CREATE_POST_ACTIVITY_SUCCESS,
    CREATE_POST_ACTIVITY_FAILURE,
    CREATE_POST_REQUEST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAILURE,
    UPDATE_POST_REQUEST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAILURE,
    GET_POST_STAT_REQUEST,
    GET_POST_STAT_SUCCESS,
    GET_POST_STAT_FAILURE,
    FETCH_USER_POSTS_REQUEST,
    FETCH_USER_POSTS_SUCCESS,
    FETCH_USER_POSTS_FAILURE,
    FETCH_USER_ACTIVITY_POSTS_REQUEST,
    FETCH_USER_ACTIVITY_POSTS_SUCCESS,
    FETCH_USER_ACTIVITY_POSTS_FAILURE,
    FETCH_POST_COMMENTS_REQUEST,
    FETCH_POST_COMMENTS_SUCCESS,
    FETCH_POST_COMMENTS_FAILURE,
    CREATE_POST_COMMENT_REQUEST,
    CREATE_POST_COMMENT_SUCCESS,
    CREATE_POST_COMMENT_FAILURE,
    DELETE_POST_COMMENT_REQUEST,
    DELETE_POST_COMMENT_SUCCESS,
    DELETE_POST_COMMENT_FAILURE,
    DELETE_POST_REQUEST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE,
    CHECK_POST_TITLE_REQUEST,
    CHECK_POST_TITLE_SUCCESS,
    CHECK_POST_TITLE_FAILURE
} from './types';

import { 
    getPosts,
    getPost,
    searchPosts,
    getPostBySlug,
    createActivity,
    createPost,
    updatePost,
    deletePost,
    getPostStats,
    getUserPosts,
    getActivityWisePosts,
    fetchPostComments,
    createPostComment,
    deletePostComment,
    checkPostTitle,
} from '../../services/postService';

export function* fetchPostsSaga({ payload }){
    try {
        const result = yield call(getPosts, payload)
        if(result){
            yield put({type: FETCH_POSTS_SUCCESS, payload: result.data})
            yield put({type: POST_FILTER_UPDATE, payload: result.filters})
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: FETCH_POSTS_FAILURE, payload: error.response })
        }
    }
}

export function* searchPostsSaga({ payload }){
    try {
        const result = yield call(searchPosts, payload)
        if(result){
            yield put({type: SEARCH_POSTS_SUCCESS, payload: result.data})
            yield put({type: POST_FILTER_UPDATE, payload: result.filters})
        }
    } catch (error) {
        if(error.response && error.response.status === 422){
            yield put({ type: SEARCH_POSTS_FAILURE, payload: error.response })
        }
    }
}

export function* getPostSaga({ payload }){
    try {
        const result = yield call(getPost, payload)
        if(result){
            yield put({ type: GET_POST_SUCCESS, payload: result.data })
        }
    } catch (error) {
        console.log(error.response)
        yield put({ type: GET_POST_FAILURE, payload: error.response })
    }
}

export function* getPostBySlugSaga({ payload }){
    try {
        const result = yield call(getPostBySlug, payload)
        if(result){
            yield put({ type: GET_POST_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: GET_POST_FAILURE, payload: error.response })
    }
}

export function* createPostActivitySaga({ payload }){
    try {
        const result = yield call(createActivity, payload)
        if(result){
            yield put({ type: CREATE_POST_ACTIVITY_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: CREATE_POST_ACTIVITY_FAILURE, payload: error.response })
    }
}

export function* createPostSaga({ payload }){
    try {
        const result = yield call(createPost, payload)
        if(result){
            yield put({ type: CREATE_POST_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: CREATE_POST_FAILURE, payload: error.response })
    }
}

export function* updatePostSaga({ payload }){
    try {
        const result = yield call(updatePost, payload)
        if(result){
            yield put({ type: UPDATE_POST_SUCCESS, payload: result.post })
        }
    } catch (error) {
        yield put({ type: UPDATE_POST_FAILURE, payload: error.response })
    }
}

export function* deletePostSaga({ payload }){
    try {
        const result = yield call(deletePost, payload)
        if(result){
            yield put({ type: DELETE_POST_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: DELETE_POST_FAILURE, payload: error.response })
    }
}

export function* getPostsStatSaga({ payload }){
    try {
        const result = yield call(getPostStats, payload)
        if(result){
            yield put({ type: GET_POST_STAT_SUCCESS, payload: result.data })
        }
    } catch (error) {
        console.log(error.response)
        yield put({ type: GET_POST_STAT_FAILURE, payload: error.response })
    }
}

export function* getUserPostsSaga({ payload }){
    try {
        const result = yield call(getUserPosts, payload)
        if(result){
            yield put({ type: FETCH_USER_POSTS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        console.log(error.response)
        yield put({ type: FETCH_USER_POSTS_FAILURE, payload: error.response })
    }
}

export function* getActivityWisePostsSaga({ payload }){
    try {
        const result = yield call(getActivityWisePosts, payload)
        if(result){
            yield put({ type: FETCH_USER_ACTIVITY_POSTS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        console.log(error.response)
        yield put({ type: FETCH_USER_ACTIVITY_POSTS_FAILURE, payload: error.response })
    }
}

export function* createPostCommentSaga({ payload }){
    try {
        const { id, data } = payload;
        const result = yield call(createPostComment, id, data)
        if(result){
            yield put({ type: CREATE_POST_COMMENT_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: CREATE_POST_COMMENT_FAILURE, payload: error.response })
    }
}

export function* fetchPostCommentsSaga({ payload }){
    try {
        const result = yield call(fetchPostComments, payload)
        if(result){
            yield put({type: FETCH_POST_COMMENTS_SUCCESS, payload: result.data})
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: FETCH_POST_COMMENTS_FAILURE, payload: error.response })
        }
    }
}

export function* deletePostCommentSaga({ payload }){
    try {
        const { postId, commentId} = payload;
        const result = yield call(deletePostComment, postId, commentId)
        if(result){
            yield put({ type: DELETE_POST_COMMENT_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: DELETE_POST_COMMENT_FAILURE, payload: error.response })
    }
}

export function* checkPostTitleSaga({ payload }){
    try {
        const { id, data } = payload;
        const result = yield call(checkPostTitle, payload)
        if(result){
            yield put({ type: CHECK_POST_TITLE_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: CHECK_POST_TITLE_FAILURE, payload: error.response })
    }
}

export default function* postSagas(){
    yield all([
        takeEvery(FETCH_POSTS_REQUEST, fetchPostsSaga),
        takeEvery(GET_POST_REQUEST, getPostSaga),
        takeEvery(SEARCH_POSTS_REQUEST, searchPostsSaga),
        takeEvery(GET_POST_BY_SLUG_REQUEST, getPostBySlugSaga),
        takeEvery(CREATE_POST_ACTIVITY_REQUEST, createPostActivitySaga),
        takeEvery(CREATE_POST_REQUEST, createPostSaga),
        takeEvery(UPDATE_POST_REQUEST, updatePostSaga),
        takeEvery(GET_POST_STAT_REQUEST, getPostsStatSaga),
        takeEvery(FETCH_USER_POSTS_REQUEST, getUserPostsSaga),
        takeEvery(FETCH_USER_ACTIVITY_POSTS_REQUEST, getActivityWisePostsSaga),
        takeEvery(FETCH_POST_COMMENTS_REQUEST, fetchPostCommentsSaga),
        takeEvery(CREATE_POST_COMMENT_REQUEST, createPostCommentSaga),
        takeEvery(DELETE_POST_REQUEST, deletePostSaga),
        takeEvery(DELETE_POST_COMMENT_REQUEST, deletePostCommentSaga),
        takeEvery(CHECK_POST_TITLE_REQUEST, checkPostTitleSaga)
    ])
}