import { 
    FETCH_COUNTRIES_REQUEST,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_FAILURE
} from './types';


const initialState = {
  items: [],
  loading: false,
  errors: null
}

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_COUNTRIES_SUCCESS:
        return {
            ...state,
            loading: false,
            items: action.payload,
            error: null
        }
    case FETCH_COUNTRIES_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            items: []
        }
    default:
        return state
  }
}

export default locationReducer