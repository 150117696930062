import React from 'react';

import Navbar from '../Navbar';
import Footer from '../Footer';
import MobileNavBar from '../MobileNavBar';

const MainLayout = ({
    children
}) => {
    return (
        <>
            <MobileNavBar />
            <Navbar />
            <main className="it-main--container">
                { children }
            </main>
            <Footer />
        </>
    )
}


export default MainLayout;