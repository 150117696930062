import { 
    FETCH_CATEGORIES_REQUEST,
    FETCH_COMPANIES_REQUEST,
    FETCH_DESIGNATIONS_REQUEST,
    FETCH_INDUSTRIES_REQUEST,
    FETCH_SITE_OPTIONS_REQUEST,
    SEARCH_USER_QUERY_REQUEST
} from './types';

export const fetchIndustries = () => {
    return {
        type: FETCH_INDUSTRIES_REQUEST
    }
}

export const fetchCategories = (params) => {
    return {
        type: FETCH_CATEGORIES_REQUEST,
        payload:params
    }
}

export const fetchCompanies = () => {
    return {
        type: FETCH_COMPANIES_REQUEST
    }
}

export const fetchDesignations = () => {
    return {
        type: FETCH_DESIGNATIONS_REQUEST
    }
}

export const fetchSiteOptions = () => {
    return {
        type: FETCH_SITE_OPTIONS_REQUEST
    }
}

export const searchUserQuery = (params) => {
    return {
        type: SEARCH_USER_QUERY_REQUEST,
        payload: params
    }
}