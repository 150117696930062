import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
import {
    CREATE_CONTACT_ENQUIRY_REQUEST,
    CREATE_CONTACT_ENQUIRY_SUCCESS,
    CREATE_CONTACT_ENQUIRY_FAILURE
} from './types';
import { createEnquiry } from '../../services/enquiryService';

export function* createEnquirySaga({ payload }){
    try {
        const result = yield call(createEnquiry, payload)
        if(result){
            yield put({ type: CREATE_CONTACT_ENQUIRY_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: CREATE_CONTACT_ENQUIRY_FAILURE, payload: error })
    }
}

export default function* sagas(){
    yield all([
        takeEvery(CREATE_CONTACT_ENQUIRY_REQUEST, createEnquirySaga),
    ])
}