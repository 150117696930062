import { 
    FETCH_POSTS_REQUEST,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
    GET_POST_REQUEST,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    POST_FILTER_UPDATE,
    SEARCH_POSTS_REQUEST,
    SEARCH_POSTS_SUCCESS,
    SEARCH_POSTS_FAILURE,
    GET_POST_BY_SLUG_REQUEST,
    CREATE_POST_ACTIVITY_REQUEST,
    CREATE_POST_ACTIVITY_SUCCESS,
    CREATE_POST_ACTIVITY_FAILURE,
    SET_LOCAL_POST,
    CREATE_POST_REQUEST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAILURE,
    UPDATE_POST_REQUEST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAILURE,
    RESET_LOCAL_POST,
    GET_POST_STAT_REQUEST,
    GET_POST_STAT_SUCCESS,
    GET_POST_STAT_FAILURE,
    FETCH_USER_POSTS_REQUEST,
    FETCH_USER_POSTS_SUCCESS,
    FETCH_USER_POSTS_FAILURE,
    FETCH_USER_ACTIVITY_POSTS_REQUEST,
    FETCH_USER_ACTIVITY_POSTS_SUCCESS,
    FETCH_USER_ACTIVITY_POSTS_FAILURE,
    FETCH_POST_COMMENTS_REQUEST,
    FETCH_POST_COMMENTS_SUCCESS,
    FETCH_POST_COMMENTS_FAILURE,
    CREATE_POST_COMMENT_REQUEST,
    CREATE_POST_COMMENT_SUCCESS,
    CREATE_POST_COMMENT_FAILURE,
    DELETE_POST_REQUEST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILURE,
    DELETE_POST_COMMENT_REQUEST,
    DELETE_POST_COMMENT_SUCCESS,
    DELETE_POST_COMMENT_FAILURE,
    CHECK_POST_TITLE_REQUEST,
    CHECK_POST_TITLE_SUCCESS,
    CHECK_POST_TITLE_FAILURE
} from './types';


const INIT_LOCAL_POST = {
    isCreatedWriter: true,
    step: 0,
    contentItems: [],
    categories: [],
    tags: []
} 

const initialState = {
  items: [],
  item: null,
  authorized: false, // false is default value
  loading: false,
  error: null,
  filters: null,
  activity: null,
  localPost: {
    ...INIT_LOCAL_POST     
  },
  created: null,
  updated: null,
  deleted: null,
  stats: null,
  userItems: [],
  userActivityPosts: [],
  postComments: null,
  createdComment: null,
  deletedComment: null,
  titleCheck: null
}

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_REQUEST:
        return { 
            ...state,
            loading: true,
            items: [],
            filters: null,
            deleted: null,
        }
    case FETCH_POSTS_SUCCESS:
        return {
            ...state,
            loading: false,
            items: action.payload,
            error: null
        }
    case FETCH_POSTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            items: [],
            filters: null
        }
    case SEARCH_POSTS_REQUEST:
        return { 
            ...state,
            loading: true,
            items: [],
            filters: null,
            deleted: null,
        }
    case SEARCH_POSTS_SUCCESS:
        return {
            ...state,
            loading: false,
            items: action.payload,
            error: null
        }
    case SEARCH_POSTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            items: [],
            filters: null
        }
    case GET_POST_BY_SLUG_REQUEST:
    case GET_POST_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            item: null,
        }
    case GET_POST_SUCCESS:
        return {
            ...state,
            loading: false,
            item: action.payload,
            error: null
        }
    case GET_POST_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            item: null
        }
    case POST_FILTER_UPDATE:
        return {
            ...state,
            filters: action.payload
        }
    case CREATE_POST_ACTIVITY_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            activity: null,
        }
    case CREATE_POST_ACTIVITY_SUCCESS:
        return {
            ...state,
            loading: false,
            activity: action.payload,
            error: null
        }
    case CREATE_POST_ACTIVITY_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            activity: null
        }
    case SET_LOCAL_POST: {
        return {
            ...state,
            localPost: { ...state.localPost, ...action.payload }
        }
    }
    case RESET_LOCAL_POST: {
        return {
            ...state,
            localPost: INIT_LOCAL_POST,
            updated: null,
            item: null
        }
    }
    case CREATE_POST_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            created: null,
        }
    case CREATE_POST_SUCCESS:
        return {
            ...state,
            loading: false,
            created: action.payload,
            error: null
        }
    case CREATE_POST_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            created: null
        }
    case UPDATE_POST_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            updated: null,
        }
    case UPDATE_POST_SUCCESS:
        return {
            ...state,
            loading: false,
            updated: action.payload,
            error: null
        }
    case UPDATE_POST_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            updated: null
        }
    case DELETE_POST_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            deleted: null,
        }
    case DELETE_POST_SUCCESS:
        return {
            ...state,
            loading: false,
            deleted: action.payload,
            error: null
        }
    case DELETE_POST_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            deleted: null
        }
    case GET_POST_STAT_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            stats: null,
        }
    case GET_POST_STAT_SUCCESS:
        return {
            ...state,
            loading: false,
            stats: action.payload,
            error: null
        }
    case GET_POST_STAT_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            stats: null
        }
    case FETCH_USER_POSTS_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            userItems: null,
        }
    case FETCH_USER_POSTS_SUCCESS:
        return {
            ...state,
            loading: false,
            userItems: action.payload,
            error: null
        }
    case FETCH_USER_POSTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            userItems: null
        }
    case FETCH_USER_ACTIVITY_POSTS_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            userActivityPosts: null,
        }
    case FETCH_USER_ACTIVITY_POSTS_SUCCESS:
        return {
            ...state,
            loading: false,
            userActivityPosts: action.payload,
            error: null
        }
    case FETCH_USER_ACTIVITY_POSTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            userActivityPosts: null
        }
    case FETCH_POST_COMMENTS_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            postComments: null,
            deletedComment: null
        }
    case FETCH_POST_COMMENTS_SUCCESS:
        return {
            ...state,
            loading: false,
            postComments: action.payload,
            error: null
        }
    case FETCH_POST_COMMENTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            postComments: null
        }
    case CREATE_POST_COMMENT_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            createdComment: null,
        }
    case CREATE_POST_COMMENT_SUCCESS:
        return {
            ...state,
            loading: false,
            createdComment: action.payload,
            error: null
        }
    case CREATE_POST_COMMENT_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            createdComment: null
        }
    case DELETE_POST_COMMENT_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            deletedComment: null,
        }
    case DELETE_POST_COMMENT_SUCCESS:
        return {
            ...state,
            loading: false,
            deletedComment: action.payload,
            error: null
        }
    case DELETE_POST_COMMENT_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            deletedComment: null
        }
    case CHECK_POST_TITLE_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            titleCheck: null,
        }
    case CHECK_POST_TITLE_SUCCESS:
        return {
            ...state,
            loading: false,
            titleCheck: action.payload,
            error: null
        }
    case CHECK_POST_TITLE_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            titleCheck: null
        }
    default:
        return state
  }
}

export default postReducer