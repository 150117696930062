import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
import { 
    GET_PAGE_BY_SLUG_REQUEST,
    GET_PAGE_BY_SLUG_SUCCESS,
    GET_PAGE_BY_SLUG_FAILURE
} from './types';
import { getCustomPage } from '../../services/dataService';

export function* getCustomPageSaga({ payload }){
    try {
        const result = yield call(getCustomPage, payload)
        if(result){
            yield put({ type: GET_PAGE_BY_SLUG_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: GET_PAGE_BY_SLUG_FAILURE, payload: error })
    }
}

export default function* sagas(){
    yield all([
        takeEvery(GET_PAGE_BY_SLUG_REQUEST, getCustomPageSaga),
    ])
}