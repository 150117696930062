export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"

export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILURE = "GET_USER_FAILURE"

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE"

export const FETCH_AUTHORS_REQUEST = "FETCH_AUTHORS_REQUEST"
export const FETCH_AUTHORS_SUCCESS = "FETCH_AUTHORS_SUCCESS"
export const FETCH_AUTHORS_FAILURE = "FETCH_AUTHORS_FAILURE"

export const GET_AUTHOR_REQUEST = "GET_AUTHOR_REQUEST"
export const GET_AUTHOR_SUCCESS = "GET_AUTHOR_SUCCESS"
export const GET_AUTHOR_FAILURE = "GET_AUTHOR_FAILURE"

export const UPDATE_PWD_REQUEST = "UPDATE_PWD_REQUEST"
export const UPDATE_PWD_SUCCESS = "UPDATE_PWD_SUCCESS"
export const UPDATE_PWD_FAILURE = "UPDATE_PWD_FAILURE"

export const SET_USER_STATE = "SET_USER_STATE"

export const FETCH_MEMBERS_REQUEST = "FETCH_MEMBERS_REQUEST"
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS"
export const FETCH_MEMBERS_FAILURE = "FETCH_MEMBERS_FAILURE"

export const FETCH_POINTS_REQUEST = "FETCH_POINTS_REQUEST"
export const FETCH_POINTS_SUCCESS = "FETCH_POINTS_SUCCESS"
export const FETCH_POINTS_FAILURE = "FETCH_POINTS_FAILURE"