import apiClient from './api-client';

export const createEnquiry = (payload) => {
    return apiClient.post(`v1/enquiries/`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}