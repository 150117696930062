export const FETCH_POSTS_REQUEST = "FETCH_POSTS_REQUEST"
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS"
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE"

export const GET_POST_BY_SLUG_REQUEST = "GET_POST_BY_SLUG_REQUEST"
export const GET_POST_REQUEST = "GET_POST_REQUEST"
export const GET_POST_SUCCESS = "GET_POST_SUCCESS"
export const GET_POST_FAILURE = "GET_POST_FAILURE"

export const POST_FILTER_UPDATE = "POST_FILTER_UPDATE"

export const SEARCH_POSTS_REQUEST = "SEARCH_POSTS_REQUEST"
export const SEARCH_POSTS_SUCCESS = "SEARCH_POSTS_SUCCESS"
export const SEARCH_POSTS_FAILURE = "SEARCH_POSTS_FAILURE"

export const CREATE_POST_ACTIVITY_REQUEST = "CREATE_POST_ACTIVITY_REQUEST"
export const CREATE_POST_ACTIVITY_SUCCESS = "CREATE_POST_ACTIVITY_SUCCESS"
export const CREATE_POST_ACTIVITY_FAILURE = "CREATE_POST_ACTIVITY_FAILURE"

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST"
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS"
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE"

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST"
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS"
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE"

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST"
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS"
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE"

export const SET_LOCAL_POST = "SET_LOCAL_POST"
export const RESET_LOCAL_POST = "RESET_LOCAL_POST"

export const GET_POST_STAT_REQUEST = "GET_POST_STAT_REQUEST"
export const GET_POST_STAT_SUCCESS = "GET_POST_STAT_SUCCESS"
export const GET_POST_STAT_FAILURE = "GET_POST_STAT_FAILURE"

export const FETCH_USER_POSTS_REQUEST = "FETCH_USER_POSTS_REQUEST"
export const FETCH_USER_POSTS_SUCCESS = "FETCH_USER_POSTS_SUCCESS"
export const FETCH_USER_POSTS_FAILURE = "FETCH_USER_POSTS_FAILURE"

export const FETCH_USER_ACTIVITY_POSTS_REQUEST = "FETCH_USER_ACTIVITY_POSTS_REQUEST"
export const FETCH_USER_ACTIVITY_POSTS_SUCCESS = "FETCH_USER_ACTIVITY_POSTS_SUCCESS"
export const FETCH_USER_ACTIVITY_POSTS_FAILURE = "FETCH_USER_ACTIVITY_POSTS_FAILURE"

export const FETCH_POST_COMMENTS_REQUEST = "FETCH_POST_COMMENTS_REQUEST"
export const FETCH_POST_COMMENTS_SUCCESS = "FETCH_POST_COMMENTS_SUCCESS"
export const FETCH_POST_COMMENTS_FAILURE = "FETCH_POST_COMMENTS_FAILURE"

export const CREATE_POST_COMMENT_REQUEST = "CREATE_POST_COMMENT_REQUEST"
export const CREATE_POST_COMMENT_SUCCESS = "CREATE_POST_COMMENT_SUCCESS"
export const CREATE_POST_COMMENT_FAILURE = "CREATE_POST_COMMENT_FAILURE"

export const DELETE_POST_COMMENT_REQUEST = "DELETE_POST_COMMENT_REQUEST"
export const DELETE_POST_COMMENT_SUCCESS = "DELETE_POST_COMMENT_SUCCESS"
export const DELETE_POST_COMMENT_FAILURE = "DELETE_POST_COMMENT_FAILURE"

export const CHECK_POST_TITLE_REQUEST = "CHECK_POST_TITLE_REQUEST"
export const CHECK_POST_TITLE_SUCCESS = "CHECK_POST_TITLE_SUCCESS"
export const CHECK_POST_TITLE_FAILURE = "CHECK_POST_TITLE_FAILURE"