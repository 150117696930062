import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
import store from 'store'

import { history } from '../../index'
import { 
    FETCH_COUNTRIES_REQUEST,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_FAILURE
} from './types';
import { getCountries } from '../../services/dataService';

export function* fetchCountries(){
    try {
        const result = yield call(getCountries)
        if(result){
            yield put({ type: FETCH_COUNTRIES_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: FETCH_COUNTRIES_FAILURE, payload: error })
    }
}

export function* logout(){
    store.remove('authToken')
    yield history.push('/')
}

export default function* locationSaga(){
    yield all([
        takeEvery(FETCH_COUNTRIES_REQUEST, fetchCountries),
    ])
}