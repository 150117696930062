import { 
    FETCH_POSTS_REQUEST,
    GET_POST_REQUEST,
    SEARCH_POSTS_REQUEST,
    GET_POST_BY_SLUG_REQUEST,
    CREATE_POST_ACTIVITY_REQUEST,
    SET_LOCAL_POST,
    CREATE_POST_REQUEST,
    UPDATE_POST_REQUEST,
    RESET_LOCAL_POST,
    GET_POST_STAT_REQUEST,
    FETCH_USER_POSTS_REQUEST,
    FETCH_USER_ACTIVITY_POSTS_REQUEST,
    FETCH_POST_COMMENTS_REQUEST,
    CREATE_POST_COMMENT_REQUEST,
    DELETE_POST_COMMENT_REQUEST,
    DELETE_POST_REQUEST,
    CHECK_POST_TITLE_REQUEST
} from './types';


export const fetchPosts = (data) => {
    return {
        type: FETCH_POSTS_REQUEST,
        payload: data
    }
}

export const getPost = (data) => {
    return {
        type: GET_POST_REQUEST,
        payload: data
    }
}

export const searchPosts = (data) => {
    return {
        type: SEARCH_POSTS_REQUEST,
        payload: data
    }
}

export const getPostBySlug = (data) => {
    return {
        type: GET_POST_BY_SLUG_REQUEST,
        payload: data
    }
}

export const createPostActivity = (id, data) => {
    return {
        type: CREATE_POST_ACTIVITY_REQUEST,
        payload: {id, data}
    }
}

export const setLocalPost = (data) => {
    return {
        type: SET_LOCAL_POST,
        payload: data
    }
}

export const createPost = (data) => {
    return {
        type: CREATE_POST_REQUEST,
        payload: data
    }
}

export const updatePost = (id, data) => {
    return {
        type: UPDATE_POST_REQUEST,
        payload: {id, data}
    }
}

export const resetLocalPost = () => {
    return {
        type: RESET_LOCAL_POST,
    }
}

export const getPostStats = (data) => {
    return {
        type: GET_POST_STAT_REQUEST,
        payload: data
    }
}

export const fetchUserPosts = (data) => {
    return {
        type: FETCH_USER_POSTS_REQUEST,
        payload: data
    }
}

export const fetchUserActivityPosts = (data) => {
    return {
        type: FETCH_USER_ACTIVITY_POSTS_REQUEST,
        payload: data
    }
}

export const fetchPostComments = (data) => {
    return {
        type: FETCH_POST_COMMENTS_REQUEST,
        payload: data
    }
}

export const createPostComment = (id, data) => {
    return {
        type: CREATE_POST_COMMENT_REQUEST,
        payload: { id, data }
    }
}

export const deletePostComment = (postId, commentId) => {
    return {
        type: DELETE_POST_COMMENT_REQUEST,
        payload: {postId, commentId}
    }
}

export const deletePost = (data) => {
    return {
        type: DELETE_POST_REQUEST,
        payload: data
    }
}

export const checkPostTitle = (data) => {
    return {
        type: CHECK_POST_TITLE_REQUEST,
        payload: data
    }
}