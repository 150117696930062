import React, { useState, useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { searchUserQuery } from '../redux/collections/actions';
import { getStaticUrl, getPostTypeById,slugify } from '../utils';

const SearchItem = ({primaryText, secondaryText, thumb, index, link}) => {
    
    return (
        <Link to={link?link:'#'}>
            <div className="search-item">
                <div className="search-thumb">
                    {thumb && <img src={`${thumb}`} alt={primaryText} /> }
                    {!thumb && <span>{index}</span>}
                </div>
                <div className="search-content">
                    <div className="search-primary-text">{primaryText}</div>
                    <div className="search-secondary-text">{secondaryText}</div>
                </div>
            </div>
        </Link>
    )
}

const SearchResult = ({
    id,
    title,
    data,
}) => {
    if(data && Array.isArray(data) && data.length>0){
        return (
            <div className="col-md-3 search-box">
                <h3>{title}</h3>
                { id==="posts" && data.map((item, idx) => <SearchItem key={item.id} primaryText={item.title} secondaryText={getPostTypeById(item.type).singularName} index={idx+1} link={`/story/${item.slug}`} />) }
                { id==="events" && data.map((item, idx) => <SearchItem key={item.id} primaryText={item.title} index={idx+1}  link={`/event/${item.slug}`} />) }
                { id==="authors" && data.map((item, idx) => <SearchItem key={item.id} primaryText={item.name} thumb={getStaticUrl(item.image)} link={`/authors/${item.id}/${slugify(item.name)}`} />) }
                { id==="members" && data.map((item, idx) => <SearchItem key={item.id} primaryText={item.name} thumb={getStaticUrl(item.image)} />) }
            </div>
        )
    }else{
        return <></>
    }
}

const GlobalSearchModal = ({
    results,
    onClose,
    isLoading,
    dispatch
}) => {
    const [userQuery, setUserQuery] = useState("");
    const searchInputEle = useRef()
    useEffect(() => {
        if(searchInputEle.current){
            searchInputEle.current.focus()
        }
    }, [])

    const delayedQuery = useCallback(debounce(q => dispatch(searchUserQuery({q})), 500), []);

    const onChange = e => {
        setUserQuery(e.target.value);
        delayedQuery(e.target.value);
    };

    return (
        <div className="search-modal">
            <div onClick={onClose} className="close-me">
                <FiX style={{width: '30px', height: '30px',fill: '#FFFFFF'}} />
            </div>
            <div className="layout">
                <div className="row">
                    <div className="col-12">
                        <input
                            className="m-search-input"
                            placeholder="Search..."
                            ref={searchInputEle}
                            onChange={onChange}
                            value={userQuery}
                        />
                    </div>
                    {
                        isLoading &&
                        <div className="col-12">
                            <h4>Searching...</h4>
                        </div>
                    }
                    <div className="col-12">
                        <div className="row">
                            { results && <SearchResult id="posts" title="Posts" data={results.posts} /> }
                            { results && <SearchResult id="events" title="Events" data={results.events} /> }
                            { results && <SearchResult id="authors" title="Authors" data={results.authors} /> }
                            { results && <SearchResult id="members" title="Members" data={results.members} /> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ collections, auth }) => {
    return {
        isLoading: collections.loading,
        results: collections.searchResult,
    }
}

export default connect(mapStateToProps)(GlobalSearchModal);