import { 
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    FETCH_AUTHORS_REQUEST,
    FETCH_AUTHORS_SUCCESS,
    FETCH_AUTHORS_FAILURE,
    GET_AUTHOR_REQUEST,
    GET_AUTHOR_SUCCESS,
    GET_AUTHOR_FAILURE,
    UPDATE_PWD_REQUEST,
    UPDATE_PWD_SUCCESS,
    UPDATE_PWD_FAILURE,
    SET_USER_STATE,
    FETCH_MEMBERS_REQUEST,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_FAILURE,
    FETCH_POINTS_REQUEST,
    FETCH_POINTS_SUCCESS,
    FETCH_POINTS_FAILURE,
} from './types';

import {
    AUTH_LOGOUT
} from '../auth/types'


const initialState = {
  items: [],
  updated: null,
  item: null,
  loading: false,
  errors: null,
  profile: null,
  authors: [],
  author: null,
  changePass: null,
  points: null
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_STATE:
        return {
            ...state, ...action.payload
        }
    case UPDATE_USER_REQUEST:
        return {
            ...state,
            loading: true
        }
    case UPDATE_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            updated: action.payload,
            error: null
        }
    case UPDATE_USER_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            updated: null
        }
    case GET_USER_REQUEST:
        return {
            ...state,
            loading: true
        }
    case GET_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            item: action.payload,
            error: null
        }
    case GET_USER_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            item: null
        }
    case UPDATE_PROFILE_REQUEST:
        return {
            ...state,
            loading: true,
            error: action.payload,
            profile: null
        }
    case UPDATE_PROFILE_SUCCESS:
        return {
            ...state,
            loading: false,
            profile: action.payload,
            error: null
        }
    case UPDATE_PROFILE_FAILURE:
        return {
            ...state,
            loading: false,
            profile: null,
            error: action.payload
        }
        
    case UPDATE_PWD_REQUEST:
        return {
            ...state,
            loading: true,
            error: null,
            changePass: null
        }

    case UPDATE_PWD_SUCCESS:
        return {
            ...state,
            loading: false,
            changePass: action.payload,
            error: null
        }

    case UPDATE_PWD_FAILURE:
        return {
            ...state,
            loading: false,
            changePass: null,
            error: action.payload
        }

    case AUTH_LOGOUT:
        return {
            ...initialState
        }

    case FETCH_AUTHORS_REQUEST:
        return {
            ...state,
            loading: true,
            authors: [],
            error: null
        }

    case FETCH_AUTHORS_SUCCESS:
        return {
            ...state,
            loading: false,
            authors: action.payload,
            error: null
        }

    case FETCH_AUTHORS_FAILURE:
        return {
            ...state,
            loading: false,
            authors: [],
            error: action.payload
        }
    
    case GET_AUTHOR_REQUEST:
        return {
            ...state,
            loading: true,
            author: null,
            error: null
        }

    case GET_AUTHOR_SUCCESS:
        return {
            ...state,
            loading: false,
            author: action.payload,
            error: null
        }

    case GET_AUTHOR_FAILURE:
        return {
            ...state,
            loading: false,
            author: null,
            error: action.payload
        }
    
    case FETCH_MEMBERS_REQUEST:
        return {
            ...state,
            loading: true,
            members: [],
            error: null
        }

    case FETCH_MEMBERS_SUCCESS:
        return {
            ...state,
            loading: false,
            members: action.payload,
            error: null
        }

    case FETCH_MEMBERS_FAILURE:
        return {
            ...state,
            loading: false,
            members: [],
            error: action.payload
        }
    
    case FETCH_POINTS_REQUEST:
        return {
            ...state,
            loading: true,
            points: null,
            error: null
        }

    case FETCH_POINTS_SUCCESS:
        return {
            ...state,
            loading: false,
            points: action.payload,
            error: null
        }

    case FETCH_POINTS_FAILURE:
        return {
            ...state,
            loading: false,
            points: null,
            error: action.payload
        }

    default:
        return state
  }
}

export default userReducer