import { all } from 'redux-saga/effects'
import auth from './auth/sagas'
import location from './locations/sagas'
import user from './user/sagas'
import collection from './collections/sagas'
import post from './post/sagas'
import customPage from './customPage/sagas'
import event from './event/sagas';
import enquiry from './enquiries/sagas';

export default function* rootSaga() {
  yield all([
    auth(),
    location(),
    user(),
    collection(),
    post(),
    customPage(),
    event(),
    enquiry(),
  ])
}
