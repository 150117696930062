export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST"
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS"
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE"

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST"
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS"
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE"

export const FETCH_UPCOMING_EVENTS_REQUEST = "FETCH_UPCOMING_EVENTS_REQUEST"
export const FETCH_UPCOMING_EVENTS_SUCCESS = "FETCH_UPCOMING_EVENTS_SUCCESS"
export const FETCH_UPCOMING_EVENTS_FAILURE = "FETCH_UPCOMING_EVENTS_FAILURE"

export const CREATE_EVENT_ACTIVITY_REQUEST = "CREATE_EVENT_ACTIVITY_REQUEST"
export const CREATE_EVENT_ACTIVITY_SUCCESS = "CREATE_EVENT_ACTIVITY_SUCCESS"
export const CREATE_EVENT_ACTIVITY_FAILURE = "CREATE_EVENT_ACTIVITY_FAILURE"

export const FETCH_LITE_EVENTS_REQUEST = "FETCH_LITE_EVENTS_REQUEST"
export const FETCH_LITE_EVENTS_SUCCESS = "FETCH_LITE_EVENTS_SUCCESS"
export const FETCH_LITE_EVENTS_FAILURE = "FETCH_LITE_EVENTS_FAILURE"