import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'

import { 
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    FETCH_AUTHORS_REQUEST,
    FETCH_AUTHORS_SUCCESS,
    FETCH_AUTHORS_FAILURE,
    GET_AUTHOR_REQUEST,
    GET_AUTHOR_SUCCESS,
    GET_AUTHOR_FAILURE,
    UPDATE_PWD_REQUEST,
    UPDATE_PWD_SUCCESS,
    UPDATE_PWD_FAILURE,
    FETCH_MEMBERS_REQUEST,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_FAILURE,
    FETCH_POINTS_REQUEST,
    FETCH_POINTS_SUCCESS,
    FETCH_POINTS_FAILURE,
} from './types';
import {
    updateUser,
    updateUserProfile,
    fetchAuthors,
    getAuthor,
    changePassword,
    fetchMembers,
    fetchMePoints
} from '../../services/userService';

export function* updateUserSaga(payload){
    try {
        const result = yield call(updateUser, payload)
        if(result){
            yield put({ type: UPDATE_USER_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: UPDATE_USER_FAILURE, payload: error })
    }
}

export function* updateUserProfileSaga(payload){
    try {
        const result = yield call(updateUserProfile, payload)
        if(result){
            yield put({ type: UPDATE_PROFILE_SUCCESS, payload: result.data })
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: UPDATE_PROFILE_FAILURE, payload: error.response.data.error })
        }
    }
}

export function* changePasswordSaga({payload}){
    try {
        const result = yield call(changePassword, payload)
        if(result){
            yield put({ type: UPDATE_PWD_SUCCESS, payload: result.data })
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: UPDATE_PWD_FAILURE, payload: error.response.data.error })
        }
    }
}

export function* getUserSaga(payload){
    try {
        const result = yield call(updateUser, payload)
        if(result){
            yield put({ type: GET_USER_SUCCESS, payload: result })
        }
    } catch (error) {
        yield put({ type: GET_USER_FAILURE, payload: error })
    }
}

export function* fetchAuthorsSaga({payload}){
    try {
        const result = yield call(fetchAuthors, payload)
        if(result){
            yield put({ type: FETCH_AUTHORS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_AUTHORS_FAILURE, payload: error })
    }
}

export function* getAuthorSaga({payload}){
    try {
        const result = yield call(getAuthor, payload)
        if(result){
            yield put({ type: GET_AUTHOR_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: GET_AUTHOR_FAILURE, payload: error })
    }
}

export function* fetchMembersSaga({payload}){
    try {
        const result = yield call(fetchMembers, payload)
        if(result){
            yield put({ type: FETCH_MEMBERS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_MEMBERS_FAILURE, payload: error })
    }
}

export function* fetchMePointsSaga({payload}){
    try {
        const result = yield call(fetchMePoints, payload)
        if(result){
            yield put({ type: FETCH_POINTS_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: FETCH_POINTS_FAILURE, payload: error })
    }
}

export default function* userSaga(){
    yield all([
        takeEvery(UPDATE_USER_REQUEST, updateUserSaga),
        takeEvery(UPDATE_PROFILE_REQUEST, updateUserProfileSaga),
        takeEvery(GET_USER_REQUEST, getUserSaga),
        takeEvery(FETCH_AUTHORS_REQUEST, fetchAuthorsSaga),
        takeEvery(GET_AUTHOR_REQUEST, getAuthorSaga),
        takeEvery(UPDATE_PWD_REQUEST, changePasswordSaga),
        takeEvery(FETCH_MEMBERS_REQUEST, fetchMembersSaga),
        takeEvery(FETCH_POINTS_REQUEST, fetchMePointsSaga)
    ]);
}