import React, { lazy, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

import Layout from './components/layouts';
import ScrollToTop from './components/ScrollToTop'; 
import SiteLoader from './components/SiteLoader'

const routes = [
    {
        path: '/',
        Component: lazy(() => import('./components/pages/home')),
        exact: true
    },
    {
        path: '/home',
        Component: lazy(() => import('./components/pages/home')),
        exact: true,
        
    },
    {
        path: '/event-registration',
        Component: lazy(() => import('./components/pages/EventRegistration')),
        exact: true,
        //onlyForAuthenticated: true,
    },
    {
        path: '/event-login',
        Component: lazy(() => import('./components/pages/EventRegistration/EventLogin')),
        exact: true,
        //onlyForAuthenticated: true,
    },
    {
        path: '/campaign-signup',
        Component: lazy(() => import('./components/pages/SocialRegistration')),
        exact: true,
        campussignupauth: true
    },
    {
        path: '/campaign-signup/thankyou',
        Component: lazy(() => import('./components/pages/SocialRegistration/ShareStoryThankyou')),
        exact: true,
        onlyForAuthenticated: true
    },
    {
        path: '/storytelling',
        Component: lazy(() => import('./components/pages/storytelling')),
        exact: true,
        storytellRot: true
        //onlyForAuthenticated: true,
    },
    {
        path: '/storytelling-login',
        Component: lazy(() => import('./components/pages/storytelling/StorytellingLogin')),
        exact: true,
        storytellRot: true
    },
    {
        path: '/storytelling/share-your-story',
        Component: lazy(() => import('./components/pages/storytelling/ShareStory')),
        exact: true,
        onlyForAuthenticated: true,
    },
    {
        path: '/storytelling/share-story-thankyou',
        Component: lazy(() => import('./components/pages/storytelling/ShareStoryThankyou')),
        exact: true,
        onlyForAuthenticated: true,
    },
    {
        path: '/contact-us',
        Component: lazy(() => import('./components/pages/contact-us')),
        exact: true,
        
    },
    {
        path: '/feedback',
        Component: lazy(() => import('./components/pages/feedback')),
        exact: true,
        
    },
    {
        path: '/collaborate-with-us',
        Component: lazy(() => import('./components/pages/collaborate-with-us')),
        exact: true,
    },
    {
        path: '/auth/login',
        Component: lazy(() => import('./components/pages/auth/login')),
        exact: true,
        onlyForGuest: true,
    },
    {
        path: '/auth/signup',
        Component: lazy(() => import('./components/pages/auth/signup')),
        exact: true,
        onlyForGuest: true,
    },
    {
        path: '/auth/signup-email',
        Component: lazy(() => import('./components/pages/auth/signup-email')),
        exact: true,
        onlyForGuest: true,
    },
    {
        path: '/auth/user-invite',
        Component: lazy(() => import('./components/pages/auth/user-invite')),
        exact: true,
        onlyForGuest: true,
    },
    {
        path: '/auth/verify-email',
        Component: lazy(() => import('./components/pages/auth/verify-email')),
        exact: true,
    },
    {
        path: '/auth/forgot-password',
        Component: lazy(() => import('./components/pages/auth/forgot-password')),
        exact: true,
        onlyForGuest: true,
    },
    {
        path: '/auth/reset-password',
        Component: lazy(() => import('./components/pages/auth/reset-password')),
        exact: true,
        onlyForGuest: true,
    },
    {
        path: '/account/on-boarding/:step',
        Component: lazy(() => import('./components/pages/on-boarding')),
        onlyForAuthenticated: true,
    },
    {
        path: '/account',
        Component: lazy(() => import('./components/pages/account')),
        exact: true,
        isGuest: true,
        onlyForAuthenticated: true
    },
    {
        path: '/account/posts',
        Component: lazy(() => import('./components/pages/account/posts')),
        exact: true,
        onlyForAuthenticated: true
    },
    {
        path: '/account/profile',
        Component: lazy(() => import('./components/pages/account/profile')),
        //exact: true,
        onlyForAuthenticated: true
    },
    {
        path: '/account/posts/:actionType',
        Component: lazy(() => import('./components/pages/account/posts/show')),
        exact: true,
        onlyForAuthenticated: true
    },
    {
        path: '/logout',
        Component: lazy(() => import('./components/Logout')),
        exact: true,
        onlyForAuthenticated: true
    },
    {
        path: '/feed',
        Component: lazy(() => import('./components/pages/my-feed')),
        onlyForAuthenticated: true,
    },
    {
        path: '/search',
        Component: lazy(() => import('./components/pages/search')),
        //onlyForAuthenticated: true,
    },
    {
        path: '/stories',
        Component: lazy(() => import('./components/pages/stories')),
        exact: true
    },
    {
        path: '/stories/:slug',
        Component: lazy(() => import('./components/pages/stories')),
        //onlyForAuthenticated: true,
    },
    {
        path: '/story/:slug',
        Component: lazy(() => import('./components/pages/stories/read')),
        //onlyForAuthenticated: true,
    },
    {
        path: '/events',
        Component: lazy(() => import('./components/pages/events')),
        //onlyForAuthenticated: true,
    },
    {
        path: '/event/:slug',
        Component: lazy(() => import('./components/pages/events/show')),
        //onlyForAuthenticated: true,
    },
    {
        path: '/authors',
        Component: lazy(() => import('./components/pages/authors')),
        exact: true
    },
    {
        path: '/community',
        Component: lazy(() => import('./components/pages/members')),
        exact: true,
        onlyForAuthenticated: true
    },
    {
        path: '/authors/:authorId/:slug',
        Component: lazy(() => import('./components/pages/authors/show')),
        exact: true
    },
    {
        path: '/write/new-story',
        Component: lazy(() => import('./components/pages/write-story')),
        onlyForAuthenticated: true,
        exact: true
    },
    {
        path: '/write/:postId/edit',
        Component: lazy(() => import('./components/pages/write-story')),
        onlyForAuthenticated: true
    },
    {
        path: '/:slug',
        Component: lazy(() => import('./components/pages/custom-pages')),
        //onlyForAuthenticated: true,
        exact: true
    }
];
// GA
ReactGA.initialize('G-2VRM4DVPJF');

// Facebook Pixel Integration, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const advancedMatching = {};
const options = {
  autoConfig: true, 
  debug: false,
};
ReactPixel.init('313141420317399', advancedMatching, options);

// LinkedIn Pixel
LinkedInTag.init("3106762", 'dc', false);

const mapStateToProps = ({ auth }) => {
    return {
        routerAnimation: 'slide-fadein-up',
        isAuthenticated: auth.authorized
    }
}

const Router = ({ history, routerAnimation, isAuthenticated, dispatch }) => {

    history.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
        ReactPixel.pageView();
        LinkedInTag.track();
        dispatch({type: 'AUTH_SET_STATE', payload: {showGlobalSearch: false}})
      }
    );

  return (
    <ConnectedRouter history={history}>
      <Helmet>
        <title>Imagine Tree</title>
      </Helmet>
      <ScrollToTop />
      <SiteLoader isLoading={false} />
      <Layout>
            <Route
                render={state => {
                    const { location } = state
                    return (
                    <SwitchTransition>
                        <CSSTransition
                            key={location.pathname}
                            appear
                            classNames={routerAnimation}
                            timeout={routerAnimation === 'none' ? 0 : 300}
                        >
                        <Switch location={location}>
                            
                            {routes.map(({ path, Component, exact, onlyForGuest, onlyForAuthenticated, storytellRot, campussignupauth }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    exact={exact}
                                    render={() => {
                                        // only for guest routes
                                        if(onlyForGuest && isAuthenticated){
                                            return <Redirect to='/' />
                                        }

                                        // only for guest routes
                                        if(storytellRot && isAuthenticated){
                                            return <Redirect to='/storytelling/share-your-story' />
                                        }

                                        // only for guest routes
                                        if(campussignupauth && isAuthenticated){
                                            return <Redirect to='/account/posts' />
                                        }

                                        // only for authenticated routes
                                        if(onlyForAuthenticated && !isAuthenticated){
                                            console.log('Not Authenticated',path, isAuthenticated)
                                            return <Redirect to='/' />
                                        }

                                        return (
                                            <div className={routerAnimation}>
                                                <Suspense fallback={<SiteLoader isLoading={true} />}>
                                                    <Component />
                                                </Suspense>
                                            </div>
                                        )
                                    }}
                                />
                            ))}
                            <Route exact path="/" render={() => <Redirect to="/" />} />
                            <Redirect to="/auth/404" />
                        </Switch>
                        </CSSTransition>
                    </SwitchTransition>
                    )
                }}
            />
      </Layout>
    </ConnectedRouter>
  );
}

export default connect(mapStateToProps)(Router);