import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './reducers';
import sagas from './sagas';


const initStore = (history) => {
    const sagaMiddleware = createSagaMiddleware()
    const routeMiddleware = routerMiddleware(history)
    const middlewares = [sagaMiddleware, routeMiddleware]

    if (process.env.NODE_ENV === 'development') {
        // middlewares.push(logger)
    }

    const store = createStore(
        rootReducer(history),
        applyMiddleware(...middlewares)
    )

    sagaMiddleware.run(sagas)
    
    return store;
}

export default initStore