import { 
    CREATE_CONTACT_ENQUIRY_REQUEST,
    CREATE_CONTACT_ENQUIRY_SUCCESS,
    CREATE_CONTACT_ENQUIRY_FAILURE,
    SET_CONTACT_ENQUIRY_REQUEST
} from './types';


const initialState = {
  created: null,
  loading: false,
  errors: null
}

const enquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONTACT_ENQUIRY_REQUEST:
        return {
            ...state,
            loading: true,
            error: null,
            created: null
        }
    case CREATE_CONTACT_ENQUIRY_SUCCESS:
        return {
            ...state,
            loading: false,
            created: action.payload,
            error: null
        }
    case CREATE_CONTACT_ENQUIRY_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            created: null
        }
    case SET_CONTACT_ENQUIRY_REQUEST:
        return {
            ...state,
            ...action.payload
        }
    default:
        return state
  }
}

export default enquiryReducer