import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa'
import find from 'lodash/find';

import { POST_TYPES } from '../utils';

(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "gwpigisojx");

const Footer = ({ socialOptions }) => {
    const { facebook, youtube, linkedin, instagram, twitter } = (socialOptions && socialOptions.values[0]) || {};
    return (
        <footer className="it-footer">
            <section className="container" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-12 col-lg-3">
                        <div className="footer-logo">
                            <img src={`/main_logo.svg`} alt="Imagine Tree" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                <ul className="it-footer--menu">
                                    <li>
                                    <h5>CONTENT</h5>
                                    </li>
                                    <li>
                                    <Link to="/stories">Stories</Link>
                                    </li>
                                    {
                                        /*
                                        POST_TYPES.map( pt => <li key={pt.id}><Link to={`/stories/${pt.slug}`}>- { pt.name }</Link></li>)
                                        */
                                    }
                                    <li>
                                    <Link to="/events">Events</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul className="it-footer--menu">
                                    <li>
                                    <h5>CONTRIBUTE</h5>
                                    </li>
                                    <li>
                                    <Link to="/share-a-story">Share a Story</Link>
                                    </li>
                                    <li>
                                    <Link to="/feedback">Feedback</Link>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="col-md-3">
                                <ul className="it-footer--menu">
                                    <li>
                                        <h5>COLLABORATE</h5>
                                    </li>
                                    <li>
                                        <Link to="/collaborate-with-us">Collaborate with Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/community">Community</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul className="it-footer--menu">
                                    <li>
                                        <h5>ABOUT</h5>
                                    </li>
                                    <li>
                                        <Link to="/frequently-asked-questions">FAQ</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <a href="https://www.tableau.com/?utm_medium=Referral&utm_source=Imagine%20Tree&placement=Logo">
                            <div className="footer-logo powered-by-logo">
                                <img src={`/powered_by_updated_2023.svg`} alt="Imagine Tree" />
                            </div>
                        </a>
                    </div>

                    <div className="col-12 col-lg-12 text-center it-footer--terms-links">
                        <Link to="/terms-and-conditions">Terms &amp; Conditions</Link> | <Link to="/privacy-policy">Privacy Statement</Link>
                    </div>
                    
                    <div className="col-12 col-lg-12 it-footer--social-links">
                        <a href={facebook}><FaFacebookF /></a>
                        <a href={linkedin}><FaLinkedinIn /></a>
                        <a href={instagram}><FaInstagram /></a>
                        <a href={youtube}><FaYoutube /></a>
                        <a href={twitter}><FaTwitter /></a>
                    </div>
                </div>
            </section>
        </footer>
    )
}

const mapStateToProps = ({ collections }) => {
    const socialOptions = find(collections.siteOptions, {key: 'social_links'})
    return {
        socialOptions
    }
}

export default connect(mapStateToProps)(Footer);