import React from 'react';
const MainLayout = ({
    children
}) => {
    return (
        <>
            <main className="it-main--container">
                { children }
            </main>
        </>
    )
}


export default MainLayout;