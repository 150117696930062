import { all, takeEvery, put, call } from 'redux-saga/effects'

import {
    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
    GET_EVENT_FAILURE,
    FETCH_EVENTS_REQUEST,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,
    CREATE_EVENT_ACTIVITY_REQUEST,
    CREATE_EVENT_ACTIVITY_SUCCESS,
    CREATE_EVENT_ACTIVITY_FAILURE,
    FETCH_UPCOMING_EVENTS_REQUEST,
    FETCH_UPCOMING_EVENTS_SUCCESS,
    FETCH_UPCOMING_EVENTS_FAILURE,
    FETCH_LITE_EVENTS_REQUEST,
    FETCH_LITE_EVENTS_SUCCESS,
    FETCH_LITE_EVENTS_FAILURE,
} from './types';

import { 
    getEvents,
    getEvent,
    joinEvent,
    fetchUpcomingEvents,
    fetchLiteEvents
} from '../../services/eventService';

export function* fetchEventsSaga({ payload }){
    try {
        const result = yield call(getEvents, payload)
        if(result){
            yield put({type: FETCH_EVENTS_SUCCESS, payload: result.data})
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: FETCH_EVENTS_FAILURE, payload: error.response.data })
        }
    }
}

export function* fetchUpcomingEventsSaga({ payload }){
    try {
        const result = yield call(fetchUpcomingEvents, payload)
        if(result){
            yield put({type: FETCH_UPCOMING_EVENTS_SUCCESS, payload: result.data})
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: FETCH_UPCOMING_EVENTS_FAILURE, payload: error.response.data })
        }
    }
}

export function* getEventSaga({ payload }){
    try {
        const result = yield call(getEvent, payload)
        if(result){
            yield put({ type: GET_EVENT_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: GET_EVENT_FAILURE, payload: error })
    }
}

export function* createEventActivitySaga({ payload }){
    try {
        const result = yield call(joinEvent, payload)
        if(result){
            yield put({ type: CREATE_EVENT_ACTIVITY_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield put({ type: CREATE_EVENT_ACTIVITY_FAILURE, payload: error })
    }
}

export function* fetchLiteEventsSaga({ payload }){
    try {
        const result = yield call(fetchLiteEvents, payload)
        if(result){
            yield put({type: FETCH_LITE_EVENTS_SUCCESS, payload: result.data})
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: FETCH_LITE_EVENTS_FAILURE, payload: error.response.data })
        }
    }
}


export default function* sagas(){
    yield all([
        takeEvery(FETCH_EVENTS_REQUEST, fetchEventsSaga),
        takeEvery(FETCH_UPCOMING_EVENTS_REQUEST, fetchUpcomingEventsSaga),
        takeEvery(GET_EVENT_REQUEST, getEventSaga),
        takeEvery(CREATE_EVENT_ACTIVITY_REQUEST, createEventActivitySaga),
        takeEvery(FETCH_LITE_EVENTS_REQUEST, fetchLiteEventsSaga)
    ])
}