import { 
    FETCH_EVENTS_REQUEST,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,
    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
    GET_EVENT_FAILURE,
    CREATE_EVENT_ACTIVITY_REQUEST,
    CREATE_EVENT_ACTIVITY_SUCCESS,
    CREATE_EVENT_ACTIVITY_FAILURE,
    FETCH_UPCOMING_EVENTS_REQUEST,
    FETCH_UPCOMING_EVENTS_SUCCESS,
    FETCH_UPCOMING_EVENTS_FAILURE,
    FETCH_LITE_EVENTS_REQUEST,
    FETCH_LITE_EVENTS_SUCCESS,
    FETCH_LITE_EVENTS_FAILURE,
} from './types';


const initialState = {
  items: [],
  item: null,
  authorized: false, // false is default value
  loading: false,
  error: null,
  activity: null,
  upcomingItems: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_REQUEST:
        return { 
            ...state,
            loading: true,
            items: []
        }
    case FETCH_EVENTS_SUCCESS:
        return {
            ...state,
            loading: false,
            items: action.payload,
            error: null
        }
    case FETCH_EVENTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            items: []
        }
    case GET_EVENT_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            item: null,
        }
    case GET_EVENT_SUCCESS:
        return {
            ...state,
            loading: false,
            item: action.payload,
            error: null
        }
    case GET_EVENT_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            item: null
        }
    case CREATE_EVENT_ACTIVITY_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            activity: null,
        }
    case CREATE_EVENT_ACTIVITY_SUCCESS:
        return {
            ...state,
            loading: false,
            activity: action.payload,
            error: null
        }
    case CREATE_EVENT_ACTIVITY_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            activity: null
        }
    case FETCH_UPCOMING_EVENTS_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
        }
    case FETCH_UPCOMING_EVENTS_SUCCESS:
        return {
            ...state,
            loading: false,
            upcomingItems: action.payload,
            error: null
        }
    case FETCH_UPCOMING_EVENTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            upcomingItems: []
        }
    case FETCH_LITE_EVENTS_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
        }
    case FETCH_LITE_EVENTS_SUCCESS:
        return {
            ...state,
            loading: false,
            liteItems: action.payload,
            error: null
        }
    case FETCH_LITE_EVENTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            liteItems: {}
        }
    default:
        return state
  }
}

export default authReducer