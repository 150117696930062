import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import {reducer as toastrReducer} from 'react-redux-toastr'


import auth from './auth/reducer';
import location from './locations/reducer';
import user from './user/reducer';
import collections from './collections/reducer';
import post from './post/reducer';
import customPage from './customPage/reducer';
import event from './event/reducer';
import enquiry from './enquiries/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    auth,
    location,
    user,
    collections,
    post,
    customPage,
    event,
    enquiry
  })
