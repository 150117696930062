import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr';
import store from 'store'


import { history } from '../../index'
import { 
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_REQUEST_STORY,
    AUTH_LOGIN_REQUEST_EVENTS,
    AUTH_LOGIN_FAILURE,
    GET_AUTH_ACCOUNT_REQUEST,
    GET_AUTH_ACCOUNT_SUCCESS,
    GET_AUTH_ACCOUNT_FAILURE,
    AUTH_LINKEDIN_REQUEST,
    AUTH_LINKEDIN_REQUEST_EVENTLOG,
    AUTH_LINKEDIN_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_REQUEST_FOR_STORYTELLING,
    REGISTER_USER_REQUEST_FOR_SOCIALUSER,
    REGISTER_USER_REQUEST_FOR_EVENTUSER,
    SUBMIT_STORY_FROM_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    AUTH_GOOGLE_REQUEST,
    AUTH_GOOGLE_REQUEST_EVENTREG,
    AUTH_GOOGLE_FAILURE,
    AUTH_LOGOUT,
    SET_STATE,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    GET_USER_INVITE_REQUEST,
    GET_USER_INVITE_SUCCESS,
    GET_USER_INVITE_FAILURE,
    RESEND_VERIFY_EMAIL_REQUEST,
    RESEND_VERIFY_EMAIL_SUCCESS,
    CREATE_POST_REQUEST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAILURE,
    CHECK_POST_TITLE_REQUEST,
    CHECK_POST_TITLE_SUCCESS,
    CHECK_POST_TITLE_FAILURE,
    RESEND_VERIFY_EMAIL_FAILURE
} from './types';

import { 
    userLogin,
    userLoginStory,
    linkedInLogin,
    googleLogin,
    getCurrentAccount,
    createUser,
    createUserQ,
    verifyEmailRequest,
    forgotPassword,
    resetPassword,
    getUserInvite,
    resendEmailVerification
} from '../../services/authService';

import { 
    getPosts,
    getPost,
    searchPosts,
    getPostBySlug,
    createActivity,
    createPost,
    updatePost,
    deletePost,
    getPostStats,
    getUserPosts,
    getActivityWisePosts,
    fetchPostComments,
    createPostComment,
    deletePostComment,
    checkPostTitle,
} from '../../services/postService';

import {
    joinEvent
} from '../../services/eventService';

import {
    updateUserProfileLink
} from '../../services/userService';

import { SET_USER_STATE } from '../user/types';

/*
export function* SubmitStoreyUserData({ payload }){
    try {

        history.push('/storytelling/share-story-thankyou')
        // const result = yield call(createUser, payload)
        // if(result){
        //     yield put({type: REGISTER_USER_SUCCESS, payload: result})
        //     yield history.push('/storytelling/share-your-story')
        // }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: REGISTER_USER_FAILURE, payload: error.response.data })
        }
    }
}
*/


export function* SubmitStoreyUserData({ payload }){
    try {
        const payloadIp = {id: payload.author, data: {socialLinks: payload.socialLinks,linkedIn: payload.linkedIn}};
        const result = yield call(createPost, payload);
        if(result){
            payload['isCreatedWriter'] = false;
            payload['step'] = 3;
            payload['categories'] = ['5f648a0b8782e906e1cf20be'];
            payload['tags'] = ['story'];
            payload['contentBlocks'] = {"id": "content_1","contentType": "text","data": "<p>"+payload.excerpt+"</p>"};
            payload['status'] = 'pending';
            const payloadPostIp = {id: result.data.id, data: payload};
            yield put({ type: CREATE_POST_SUCCESS, payload: result.data })
            yield call(updateUserProfileLink, payloadIp);
            yield call(updatePost, payloadPostIp);
            yield history.push('/storytelling/share-story-thankyou');
        }
    } catch (error) {
        yield put({ type: CREATE_POST_FAILURE, payload: error.response })
        yield toastr.error("Error",error.response.data.error[0].message);
    }
}

export function* createUserSagaEventUser({ payload }){
    try {
        const slughs = window.location.hash && window.location.hash && window.location.hash.split('#')[1];
        const payloadi = {id: payload.eventid, data: {action: "join"}};
        if(payload.utmParams){payloadi.data['utmParams'] = payload.utmParams;}
        const result = yield call(createUserQ, payload)
        if(result){
            yield put({type: REGISTER_USER_SUCCESS, payload: result})
            yield call(joinEvent, payloadi)
            yield history.push('/event/'+slughs)
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: REGISTER_USER_FAILURE, payload: error.response.data })
            yield toastr.error("Error",error.response.data.error[0].message);
        }
    }
}

export function* createUserSagaSocialUser({ payload }){
    try {
        const result = yield call(createUserQ, payload)
        if(result){
            yield put({type: REGISTER_USER_SUCCESS, payload: result})
            yield history.push('/campaign-signup/thankyou')
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: REGISTER_USER_FAILURE, payload: error.response.data })
            yield toastr.error("Error",error.response.data.error[0].message);
        }
    }
}

export function* createUserSagaStoryTelling({ payload }){
    try {
        const result = yield call(createUserQ, payload)
        if(result){
            yield put({type: REGISTER_USER_SUCCESS, payload: result})
            yield history.push('/storytelling/share-your-story')
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: REGISTER_USER_FAILURE, payload: error.response.data })
            yield toastr.error("Error",error.response.data.error[0].message);
        }
    }
}

export function* createUserSaga({ payload }){
    try {
        const result = yield call(createUser, payload)
        if(result){
            yield put({type: REGISTER_USER_SUCCESS, payload: result})
            yield history.push('/account/on-boarding/step-1')
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: REGISTER_USER_FAILURE, payload: error.response.data })
        }
    }
}

export function* userLoginSaga({ payload }){
    const  { email, password, remember, loginAs } = payload
    try {
        const result = yield call(userLogin, { email, password, remember, loginAs })
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            const redirectUrl = yield store.get('redirectAfterAuth');
            if(redirectUrl){
                yield store.remove('redirectAfterAuth');
                yield history.replace(redirectUrl)
            }
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_LOGIN_FAILURE, payload: error.response.data})
        }
    }
}

export function* userLoginSagaStory({ payload }){
    const  { email, password, remember, loginAs } = payload
    try {
        const result = yield call(userLoginStory, { email, password, remember, loginAs })
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            const redirectUrl = yield store.get('redirectAfterAuth');
            if(redirectUrl){
                yield store.remove('redirectAfterAuth');
                yield history.replace(redirectUrl)
            }
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_LOGIN_FAILURE, payload: error.response.data})
        }
    }
}

//userLoginSagaEvent
export function* userLoginSagaEvent({ payload }){
    const  { email, password, remember, loginAs } = payload
    try {
        const slughs = window.location.hash && window.location.hash && window.location.hash.split('#')[1];
        const payloadi = {id: payload.eventid, data: {action: "join"}};
        if(payload.utmParams){payloadi.data['utmParams'] = payload.utmParams;}
        const result = yield call(userLoginStory, { email, password, remember, loginAs })
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            yield call(joinEvent, payloadi)
            yield history.push('/event/'+slughs)
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_LOGIN_FAILURE, payload: error.response.data})
        }
    }
}

export function* userLinkedInLoginSaga({ payload }){
    try {
        const result = yield call(linkedInLogin, payload)
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            if(result.isNewUser){
                yield history.push('/account/on-boarding/step-1')
            }else{
                const redirectUrl = yield store.get('redirectAfterAuth');
                if(redirectUrl){
                    yield store.remove('redirectAfterAuth');
                    yield history.replace(redirectUrl)
                }else{
                    yield history.push('/')
                }
            }
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_LINKEDIN_FAILURE, payload: error.response.data })
        }
    }
}

export function* userLinkedInLoginSagaEventLog({ payload }){
    try {
        const slughs = window.location.hash && window.location.hash && window.location.hash.split('#')[1];
        const result = yield call(linkedInLogin, payload)
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            if(result.isNewUser){
                yield history.push('/account/on-boarding/step-1')
            }else{
                const redirectUrl = yield store.get('redirectAfterAuth');
                if(redirectUrl){
                    yield store.remove('redirectAfterAuth');
                    yield history.replace(redirectUrl)
                }else{
                    yield history.push('/event/'+slughs)
                }
            }
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_LINKEDIN_FAILURE, payload: error.response.data })
        }
    }
}

export function* userGoogleLoginSaga({ payload }){
    try {
        const result = yield call(googleLogin, payload)
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            if(result.isNewUser){
                yield history.push('/account/on-boarding/step-1')
            }else{
                const redirectUrl = yield store.get('redirectAfterAuth');
                if(redirectUrl){
                    yield store.remove('redirectAfterAuth');
                    yield history.replace(redirectUrl)
                }else{
                    yield history.push('/')
                }
            }
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_GOOGLE_FAILURE, payload: error.response.data })
        }
    }
}

export function* userGoogleLoginSagaEventLog({ payload }){
    try {
        const slughs = window.location.hash && window.location.hash && window.location.hash.split('#')[1];
        const result = yield call(googleLogin, payload)
        if(result){
            yield put({type: GET_AUTH_ACCOUNT_REQUEST})
            if(result.isNewUser){
                yield history.push('/event/'+slughs)
            }else{
                const redirectUrl = yield store.get('redirectAfterAuth');
                if(redirectUrl){
                    yield store.remove('redirectAfterAuth');
                    yield history.replace(redirectUrl)
                }else{
                    yield history.push('/event/'+slughs)
                }
            }
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: AUTH_GOOGLE_FAILURE, payload: error.response.data })
        }
    }
}

export function* loadUserAccount(){
    if(store.get('authToken')){
        yield put({
            type: SET_STATE,
            payload: {
                accountLoading: true,
            },
        })
        try {
            const result = yield call(getCurrentAccount)
            if(result){
                yield put({ type: GET_AUTH_ACCOUNT_SUCCESS, payload: result.data })
            }
        } catch (error) {
            if(error.response.status === 401)(
                store.remove('authToken')
            )
            yield put({ type: GET_AUTH_ACCOUNT_FAILURE, payload: error.response.data })
        }
    }
}

export function* verifyEmailSaga({payload}){
    try {
        const result = yield call(verifyEmailRequest, payload)
        if(result){
            yield put({ type: VERIFY_EMAIL_SUCCESS, payload: result.message })
            yield put({ type: GET_AUTH_ACCOUNT_REQUEST })
        }
    } catch (error) {
        //yield put({ type: VERIFY_EMAIL_FAILURE, payload: error.response.data })
        if(error.response.status === 422){
            yield put({ type: VERIFY_EMAIL_FAILURE, payload: error.response.data })
        }
    }
}

export function* forgotPasswordSaga({payload}){
    try {
        const result = yield call(forgotPassword, payload)
        if(result){
            yield put({type: FORGOT_PASSWORD_SUCCESS, payload: result})
            if(result.message){
                yield toastr.success("Reset Mail Sent", result.message);
                yield history.replace(`/auth/reset-password?uid=${result.uid}`)
            }
        }
    } catch (error) {
        if(error.response && error.response.status === 422){
            yield put({ type: FORGOT_PASSWORD_FAILURE, payload: error.response.data })
        }
    }
}

export function* resetPasswordSaga({payload}){
    try {
        const result = yield call(resetPassword, payload)
        if(result){
            yield put({type: RESET_PASSWORD_SUCCESS, payload: result})
            if(result.message){
                yield toastr.success("Password Updated", result.message);
                yield history.replace('/auth/login')
            }
        }
    } catch (error) {
        if(error.response && error.response.status === 422){
            yield put({ type: RESET_PASSWORD_FAILURE, payload: error.response.data })
        }
    }
}

export function* getUserInviteSaga({payload}){
    try {
        const { id, params } = payload;
        const result = yield call(getUserInvite, id, params)
        if(result){
            yield put({ type: GET_USER_INVITE_SUCCESS, payload: result.data })
        }
    } catch (error) {
        if(error.response.status === 404){
            yield put({ type: GET_USER_INVITE_FAILURE, payload: error.response.data })
        }else if(error.response.status === 422){
            yield put({ type: GET_USER_INVITE_FAILURE, payload: error.response.data })
        }
    }
}

export function* logout(){
    store.remove('authToken')
    yield history.push('/')
}

export function* noIdeaButCallItAsFix(){
    store.remove('authToken')
    yield history.push('/')
}

export function* resendEmailVerificationSaga({payload}){
    try {
        const result = yield call(resendEmailVerification, payload)
        if(result){
            yield toastr.success("Success", result.message);
            yield put({ type: RESEND_VERIFY_EMAIL_SUCCESS, payload: result.message })
        }
    } catch (error) {
        if(error.response.status === 422){
            yield put({ type: RESEND_VERIFY_EMAIL_FAILURE, payload: error.response.data })
        }
    }
}

export function* checkPostTitleSaga({ payload }){
    try {
        const { id, data } = payload;
        const result = yield call(checkPostTitle, payload)
        if(result){
            yield put({ type: CHECK_POST_TITLE_SUCCESS, payload: result.data })
        }
    } catch (error) {
        yield toastr.error("Error",'Post title already exists.');
        yield put({ type: CHECK_POST_TITLE_FAILURE, payload: error.response })
    }
}


export default function* authSaga(){
    yield all([
        takeEvery(SUBMIT_STORY_FROM_USER, SubmitStoreyUserData),
        takeEvery(REGISTER_USER_REQUEST_FOR_STORYTELLING, createUserSagaStoryTelling),
        takeEvery(REGISTER_USER_REQUEST_FOR_SOCIALUSER, createUserSagaSocialUser),
        takeEvery(REGISTER_USER_REQUEST_FOR_EVENTUSER, createUserSagaEventUser),
        takeEvery(REGISTER_USER_REQUEST, createUserSaga),
        takeEvery(AUTH_LOGIN_REQUEST, userLoginSaga),
        takeEvery(AUTH_LOGIN_REQUEST_STORY, userLoginSagaStory),
        takeEvery(AUTH_LOGIN_REQUEST_EVENTS, userLoginSagaEvent),
        takeEvery(AUTH_LINKEDIN_REQUEST, userLinkedInLoginSaga),
        takeEvery(AUTH_LINKEDIN_REQUEST_EVENTLOG, userLinkedInLoginSagaEventLog),
        takeEvery(AUTH_GOOGLE_REQUEST, userGoogleLoginSaga),
        takeEvery(AUTH_GOOGLE_REQUEST_EVENTREG, userGoogleLoginSagaEventLog),
        takeEvery(GET_AUTH_ACCOUNT_REQUEST, loadUserAccount),
        takeEvery(AUTH_LOGOUT, logout),
        takeEvery(VERIFY_EMAIL_REQUEST, verifyEmailSaga),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
        takeEvery(RESET_PASSWORD_REQUEST, resetPasswordSaga),
        takeEvery(GET_USER_INVITE_REQUEST, getUserInviteSaga),
        takeEvery(RESEND_VERIFY_EMAIL_REQUEST, resendEmailVerificationSaga),
        takeEvery("noIdeaButCallItAsFix",noIdeaButCallItAsFix),
        takeEvery(CHECK_POST_TITLE_REQUEST, checkPostTitleSaga),
        loadUserAccount()
    ])
}