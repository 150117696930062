import apiClient from './api-client';

export const getEvents = (params) => {
    console.log('params', params)
    return apiClient.get('v1/events/feed', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getEvent = (slug) => {
    return apiClient.get(`v1/events/s/${slug}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const joinEvent = (payload) => {
    const { id, data } = payload;
    return apiClient.post(`v1/events/${id}/activity`, data)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchUpcomingEvents = (params) => {
    return apiClient.get('v1/events/upcoming', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchLiteEvents = (params) => {
    return apiClient.get('v1/events/lite', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}