import store from 'store';
import apiClient from './api-client';

export const createUserQ = ( payload ) => {
    return apiClient.post('auth/register_q', payload)
        .then( response => {
            if(response){
                const { token } = response.data
                if(token) {
                    store.set('authToken', token)
                }
                return response.data
            }
            return false
        })
}

export const createUser = ( payload ) => {
    return apiClient.post('auth/register', payload)
        .then( response => {
            if(response){
                const { token } = response.data
                if(token) {
                    store.set('authToken', token)
                }
                return response.data
            }
            return false
        })
}

export const userLogin = ( creds ) => {
    return apiClient.post('auth/login', creds)
        .then( response => {
            if(response){
                const { token } = response.data
                if(token) {
                    store.set('authToken', token)
                }
                return response.data
            }
            return false
        })
}

export const userLoginStory = ( creds ) => {
    return apiClient.post('auth/loginstory', creds)
        .then( response => {
            if(response){
                const { token } = response.data
                if(token) {
                    store.set('authToken', token)
                }
                return response.data
            }
            return false
        })
}

export const linkedInLogin = ( params ) => {
    return apiClient.get('auth/linkedin/callback', { params })
        .then( response => {
            if(response){
                const { token } = response.data;
                if(token) {
                    store.set('authToken', token)
                }
                return response.data
            }
            return false
        })
}

export const googleLogin = ( params ) => {
    console.log('googleLogin', params)
    return apiClient.post('auth/google/login', params)
        .then( response => {
            if(response){
                const { token } = response.data;
                if(token) {
                    store.set('authToken', token)
                }
                return response.data
            }
            return false
        })
}

export const getCurrentAccount = () => {
    return apiClient.get('v1/users/me')
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const verifyEmailRequest = (params) => {
    return apiClient.get('auth/verify-email', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const forgotPassword = ( params ) => {
    return apiClient.post('auth/forgot-password', params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const resetPassword = ( params ) => {
    return apiClient.post('auth/reset-password', params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getUserInvite = ( token, params ) => {
    return apiClient.get(`v1/user-invites/token/${token}`, { params })
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const resendEmailVerification = ( params ) => {
    return apiClient.post('auth/resend-verification-mail', params)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}