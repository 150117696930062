import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
//import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { createActivity } from '../services/postService';

export const useComponentVisible = (initialIsVisible) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

export const useCustomForm = (initialValues, validate, onSubmit, serverErrors) => {
    const [ values, setValues ] = useState(initialValues);
    const [ errors, setErrors ] = useState({});
    const [ isSubmitting, setSubmitting ] = useState(false);

    useEffect(() => {
        if(serverErrors && isArray(serverErrors)){
            const newErrors = {};
            for(let i=0; i<serverErrors.length; i++){
                newErrors[serverErrors[i].param] = serverErrors[i].message;
            }
            setErrors( err => ({...newErrors, ...err}))
        }
    }, [serverErrors]);

    const handleChange = (event) => {
        if(event.target.type === "checkbox"){
            const oldVals = values[event.target.name];
            let newVals = [];
            if(event.target.checked){
                newVals = [...oldVals, event.target.value]
            }else{
                newVals = oldVals.filter( item => item!==event.target.value )
            }
            setValues({
                ...values,
                [event.target.name] : newVals
            })
        }else{
            setValues({
                ...values,
                [event.target.name] : event.target.value
            })
        }
    }

    const setValue = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
    }

    const resetValues = () => {
        setValues(initialValues)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        if(isEmpty(validationErrors)){
            setSubmitting(true);
            onSubmit(values)
        }
    }

    return {
        handleSubmit,
        handleChange,
        setValue,
        values,
        errors,
        isSubmitting,
        resetValues,
        setValues
    };
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const usePostAnalytics = (id, isAuthorized) => {
    const [postId, setPostId] = useState(null);
    const [isViewSent, setIsViewSent] = useState(false);
    const [isReadCompleteSent, setIsReadCompleteSent] = useState(false);
    const bottomMargin = 400;

    useEffect(() => {
        if(id && id !== postId){
            setPostId(id)
            //console.log('usePostAnalytics:ID_UPDATE')
        }
    }, [id, postId]);

    useEffect(() => {
        if(isAuthorized && postId && !isViewSent){
            setIsViewSent(true);
            createActivity({id: postId, data: { action: 'view' }}).then((res) => {
                //console.log('View Sent', postId);
            })
        }
    }, [isAuthorized, postId, isViewSent]);
    
    useLayoutEffect(() => {
        const handleScroll = (e) => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = windowHeight + window.pageYOffset;
            
            if (windowBottom >= (docHeight-bottomMargin)) {
                if(isAuthorized && postId && !isReadCompleteSent){
                    setIsReadCompleteSent(true);
                    createActivity({id: postId, data: { action: 'read_complete' }}).then((res) => {
                        console.log('End View Sent', postId);
                    })
                }
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isAuthorized, postId, isReadCompleteSent]);
}