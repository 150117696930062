import { 
    FETCH_INDUSTRIES_REQUEST,
    FETCH_INDUSTRIES_SUCCESS,
    FETCH_INDUSTRIES_FAILURE,
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_COMPANIES_REQUEST,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILURE,
    FETCH_DESIGNATIONS_REQUEST,
    FETCH_DESIGNATIONS_SUCCESS,
    FETCH_DESIGNATIONS_FAILURE,
    FETCH_SITE_OPTIONS_REQUEST,
    FETCH_SITE_OPTIONS_SUCCESS,
    FETCH_SITE_OPTIONS_FAILURE,
    SEARCH_USER_QUERY_REQUEST,
    SEARCH_USER_QUERY_SUCCESS,
    SEARCH_USER_QUERY_FAILURE
} from './types';


const initialState = {
  industries: [],
  designations: [],
  categories: [],
  companies: [],
  siteOptions: [],
  searchResult: {},
  loading: false,
  errors: null
}

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INDUSTRIES_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_INDUSTRIES_SUCCESS:
        return {
            ...state,
            loading: false,
            industries: action.payload,
            error: null
        }
    case FETCH_INDUSTRIES_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            industries: []
        }
    case FETCH_CATEGORIES_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_CATEGORIES_SUCCESS:
        return {
            ...state,
            loading: false,
            categories: action.payload,
            error: null
        }
    case FETCH_CATEGORIES_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            categories: []
        }
    case FETCH_COMPANIES_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_COMPANIES_SUCCESS:
        return {
            ...state,
            loading: false,
            companies: action.payload,
            error: null
        }
    case FETCH_COMPANIES_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            companies: []
        }
    
    case FETCH_DESIGNATIONS_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_DESIGNATIONS_SUCCESS:
        return {
            ...state,
            loading: false,
            designations: action.payload,
            error: null
        }
    case FETCH_DESIGNATIONS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            designations: []
        }
    
    case FETCH_SITE_OPTIONS_REQUEST:
        return {
            ...state,
            loading: true
        }
    case FETCH_SITE_OPTIONS_SUCCESS:
        return {
            ...state,
            loading: false,
            siteOptions: action.payload,
            error: null
        }
    case FETCH_SITE_OPTIONS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            siteOptions: []
        }
    case SEARCH_USER_QUERY_REQUEST:
        return {
            ...state,
            loading: true,
            searchResult: {}
        }
    case SEARCH_USER_QUERY_SUCCESS:
        return {
            ...state,
            loading: false,
            searchResult: action.payload,
            error: null
        }
    case SEARCH_USER_QUERY_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            searchResult: {}
        }
    case "AUTH_SET_STATE":
        if(action.payload.showGlobalSearch){
            return {
                ...state,
                searchResult: {}
            }
        }
        return state
    default:
        return state
  }
}

export default locationReducer