import apiClient from './api-client';

export const getCountries = () => {
    return apiClient.get('v1/locations/countries')
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchDesignations = () => {
    return apiClient.get('v1/designations/lite')
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchCompanies = (params) => {
    return apiClient.get('v1/companies/lite', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchIndustries = () => {
    return apiClient.get('v1/industries/lite')
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchCategories = (params) => {
    return apiClient.get('v1/categories/lite', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchSiteOptions = () => {
    return apiClient.get('v1/site-options')
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getCustomPage = (slug) => {
    return apiClient.get(`v1/custom-pages/s/${slug}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const searchUserQuery = (params) => {
    return apiClient.get(`v1/stats/lite-search`, {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}