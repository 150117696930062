import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FiMenu, FiPlus, FiMinus, FiX } from 'react-icons/fi';
import { BsArrowRight } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import { connect } from 'react-redux'

import { resetLocalPost } from '../redux/post/actions';
import { POST_TYPES, getStaticUrl, getInitials, getUserBadge, getUserBadgeMatrix3 } from '../utils';

const MENUS = [
    {
        id: 'events',
        label: 'Events',
        to: '/events',
        isRoot: true,
        childs: [
            
        ]
    },
    {
        id: 'stories',
        label: 'Stories',
        to: '/stories',
        isRoot: true,
        childs: [
            ...POST_TYPES.map((pt) => ( { id: pt.id, to: `/stories/${pt.slug}`, label: pt.name}))
        ]
    },
    {
        id: 'contribute',
        label: 'Contribute',
        to: '/contribute',
        isRoot: true,
        childs: [
            {
                to: '/share-a-story',
                label: 'Share A Story'
            },
            /*
            {
                to: '/write-a-book-review',
                label: 'Write A Book Review'
            },
            {
                to: '/feedback',
                label: 'Submit Feedback'
            },
            */
            {
                to: '/collaborate-with-us',
                label: 'Collaborate With Us'
            },
        ]
    },
    {
        id: 'about',
        label: 'About',
        to: '/about',
        isRoot: true,
        childs: [
            {
                to: '/about',
                label: 'What is Imagine Tree'
            },
            /*
            {
                to: '/the-team',
                label: 'The People Behind Imagine Tree'
            },
            {
                to: '/gallery',
                label: 'Gallery'
            },*/
            {
                to: '/frequently-asked-questions',
                label: 'FAQ'
            },
            {
                to: '/community',
                label: 'Community'
            },
        ]
    },
    {
        id: 'contact',
        label: 'Contact',
        to: '/contact-us',
        isRoot: true,
        childs: [
            
        ]
    },
    {
        id: 'manage-account',
        label: 'Manage Account',
        isRoot: true,
        onlyAuthenticated: true,
        isUserBlock: true,
        childs: [
            {
                id: 'my-feed',
                label: 'My Feed',
                to: '/feed'
            },
            {
                id: 'my-stories',
                label: 'My Journey',
                to: '/account/posts'
            },
            {
                id: 'my-settings',
                label: 'Profile',
                to: '/account/profile/edit'
            },
            {
                id: 'logout',
                label: 'Sign out',
                to: '/logout'
            },
        ]
    },
]

const MobileNavBar = ({
    isAuthenticated,
    user,
    dispatch
}) => {
    const [ menuState, setMenuState ] = useState({ showMenu: false, activeMenu: '' })
    const { showMenu, activeMenu } = menuState;
    
    const handleMenuClose = () => {
        setTimeout(() => {
            setMenuState({ ...menuState, showMenu: false, activeMenu: ''})
        }, 100);
    }

    const handleShowMenu = (current) => {
        setMenuState({...menuState, showMenu: current})
    }

    const handleActiveMenu = (current) => {
        if(current === activeMenu){
            setMenuState({...menuState, activeMenu: ''})
        }else{
            setMenuState({...menuState, activeMenu: current})
        }
    }

    const handleGlobalSearch = (isShowing) => {
        dispatch({type: 'AUTH_SET_STATE', payload: {showGlobalSearch: isShowing}})
    }

    const userPic = user && getStaticUrl(user.image);

    const userBadge = getUserBadge((user && user.profile && user.profile.earnedPoints) || 0)
    const badgeV2 = getUserBadgeMatrix3((user && user.badges) || {})
    let completedPercentages = 0;

    if(user && user.profile && user.profile.earnedPoints>0){
        completedPercentages = (user.profile.earnedPoints/1000) * 100
    }

    return(
        <div className="it-mobile-navbar">
            <div className="nav-logo" >
                <Link to='/' onClick={() => handleShowMenu(false)}><img src={`/assets/images/write-logo.png`} alt="Imagine Tree" /></Link>
            </div>
            <div className="right-nav-bar">
                {
                    !isAuthenticated &&
                    <Link to="/auth/signup" className="btn-it-primary" style={{marginRight: '10px'}} onClick={handleMenuClose}>Join Now</Link>
                }
                {
                    isAuthenticated && user && user.role !== "basic" &&
                    <Link to="/write/new-story" className="btn-it-primary" style={{marginRight: '10px'}} onClick={() => dispatch(resetLocalPost())}>Publish Post</Link>
                }
                <AiOutlineSearch style={{marginRight: '8px'}} onClick={() => handleGlobalSearch(true)} />
                {
                    showMenu && <FiX onClick={() => handleShowMenu(!showMenu)} />
                }
                {
                    !showMenu && <FiMenu onClick={() => handleShowMenu(!showMenu)} />
                }
                
            </div>

            <div className={showMenu ? "mobile-nav-menu active" : "mobile-nav-menu"}>
                <ul className="main-menu">
                    {
                        MENUS.filter( m => ((isAuthenticated && m.onlyAuthenticated) || (!m.onlyAuthenticated)) ).map((menu) => {
                            const hasChilds = menu.childs.length > 0;
                            const isActiveMenu = activeMenu === menu.id;
                            const submenuHeight = 48 * menu.childs.length;
                            return (
                                <li key={menu.id} onClick={ () => handleActiveMenu(menu.id) }>
                                    {
                                        menu.isUserBlock && user && userPic && <img className="user-logo" src={userPic} alt={user && user.name} />
                                    }
                                    {
                                        menu.isUserBlock && user && !userPic && <div className="user-logo">{getInitials(user.name)}</div>
                                    }
                                    {
                                        hasChilds ? menu.label : <Link to={menu.to} className="d-block" onClick={() => handleMenuClose()}>{menu.label}</Link>
                                    }
                                    {
                                        hasChilds && <div className="expand-icon">{ isActiveMenu ? <FiMinus /> : <FiPlus /> }</div>
                                    }
                                    {
                                        hasChilds && 
                                        <ul 
                                            className={isActiveMenu ? `sub-menu active` : `sub-menu`}
                                            style={{ height: isActiveMenu ? `${submenuHeight}px` : '0px' }}>
                                            
                                            {
                                                menu.childs.map( childMenu => {
                                                    return(
                                                        <li key={`${menu.id}-${childMenu.to}`}>
                                                            <Link onClick={() => handleMenuClose()} to={childMenu.to}>{childMenu.label} <BsArrowRight style={{fill: 'var(--primary)'}} /> </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </li>
                            )
                        })
                    }
                    {
                        user &&
                        <li className="user-points-item">
                            <div className="user-points">
                                <img className="badge-icon" src={`/assets/images/badges/${userBadge.icon_prefix}${badgeV2.badgeIconCode}.png`} alt="/assets/images/badges/legend_3.png" />
                                <div className="point-progress-bar">
                                <div className="point-labels"><span>{badgeV2.title}</span> <span>{(user.profile && user.profile.earnedPoints) || 0} Points</span></div>
                                <div className="progress-bar">
                                    <div className="active-progres" style={{width: `${completedPercentages}%`, backgroundColor: `${userBadge.color}`}} />
                                </div>
                                </div>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => ({
    isAuthenticated: auth.authorized,
    user: auth.user
})
  
export default withRouter(connect(mapStateToProps)(MobileNavBar));