import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { hotjar } from 'react-hotjar';
import LinkedInTag from 'react-linkedin-insight';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './styles/app.scss';

import initStore from './redux/store';
import Router from './router'
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
})

const imagineTreeStore = initStore(history);

ReactDOM.render(
  <Provider store={imagineTreeStore}>
    <Router history={history} />
    <ReduxToastr
      timeOut={3000}
      newestOnTop={false}
      preventDuplicates
      getState={(state) => state.toastr} // This is the default
      closeOnToastrClick/>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

hotjar.initialize(2151023, 6);

export { imagineTreeStore as store, history };