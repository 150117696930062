import {
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_REQUEST_STORY,
    AUTH_LOGIN_REQUEST_EVENTS,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAILURE,
    AUTH_GOOGLE_REQUEST,
    AUTH_GOOGLE_REQUEST_EVENTREG,
    AUTH_GOOGLE_FAILURE,
    AUTH_LINKEDIN_REQUEST,
    AUTH_LINKEDIN_FAILURE,
    GET_AUTH_ACCOUNT_REQUEST,
    GET_AUTH_ACCOUNT_SUCCESS,
    GET_AUTH_ACCOUNT_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    AUTH_LOGOUT,
    SET_STATE,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    GET_USER_INVITE_REQUEST,
    GET_USER_INVITE_SUCCESS,
    GET_USER_INVITE_FAILURE,
    RESEND_VERIFY_EMAIL_REQUEST,
    RESEND_VERIFY_EMAIL_SUCCESS,
    RESEND_VERIFY_EMAIL_FAILURE,
    CHECK_POST_TITLE_REQUEST,
    CHECK_POST_TITLE_SUCCESS,
    CHECK_POST_TITLE_FAILURE,
    REGISTER_USER_REQUEST_FOR_STORYTELLING,
    REGISTER_USER_REQUEST_FOR_EVENTUSER,
    REGISTER_USER_REQUEST_FOR_SOCIALUSER
} from './types';


const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  authorized: false,
  accountLoading: false,
  loading: false,
  error: null,
  verifyEmailResp: null,
  userInvite: null,
  showGlobalSearch: false,
  resendVerifyEmailResp: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
        return { 
            ...state,
            loading: true 
        }
    case AUTH_LOGIN_REQUEST_STORY:
        return { 
            ...state,
            loading: true ,
            error: null
        }
    case AUTH_LOGIN_REQUEST_EVENTS:
        return { 
            ...state,
            loading: true ,
            error: null
        }
    case AUTH_LOGIN_SUCCESS:
        return {
            ...state,
            loading: false,
            token: action.payload,
            error: null
        }
    case AUTH_LOGIN_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            user: null
        }

    case GET_AUTH_ACCOUNT_REQUEST:
        return { 
            ...state,
            accountLoading: true,
            error: null,
            //user: null,
        }
    case GET_AUTH_ACCOUNT_SUCCESS:
        return {
            ...state,
            accountLoading: false,
            authorized: true,
            user: action.payload,
            error: null
        }
    case GET_AUTH_ACCOUNT_FAILURE:
        return {
            ...state,
            accountLoading: false,
            authorized: false,
            error: action.payload,
            user: null
        }
    
    case AUTH_LOGOUT:
        return {
            ...state,
            authorized: false,
            loading: false,
            user: null,
            error: null
        }

    case REGISTER_USER_REQUEST:
        return {
            ...state,
            loading: true
        }

    case REGISTER_USER_REQUEST_FOR_STORYTELLING:
        return {
            ...state,
            loading: true
        }

    case REGISTER_USER_REQUEST_FOR_SOCIALUSER:
        return {
            ...state,
            loading: true
        }

    case REGISTER_USER_REQUEST_FOR_EVENTUSER:
        return {
            ...state,
            loading: true
        }

    case REGISTER_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            authorized: true,
            user: action.payload.user,
            error: null
        }
    case REGISTER_USER_FAILURE:
        return {
            ...state,
            loading: false,
            authorized: false,
            error: action.payload,
            user: null
        }

    case AUTH_LINKEDIN_REQUEST:
    case AUTH_GOOGLE_REQUEST:
        return{
            ...state,
            loading: true,
            accountLoading: true,
            error: null,
            user: null,
        }
    case AUTH_GOOGLE_REQUEST_EVENTREG:
        return{
            ...state,
            loading: true,
            accountLoading: true,
            error: null,
            user: null,
        }
    case AUTH_LINKEDIN_FAILURE:
    case AUTH_GOOGLE_FAILURE:
        return {
            ...state,
            loading: false,
            accountLoading: false,
            error: action.payload,
        }
    case SET_STATE:
        return {...state, ...action.payload}
    case VERIFY_EMAIL_REQUEST:
        return { 
            ...state,
            loading: true,
            verifyEmailResp: null,
            error: null,
        }
    case VERIFY_EMAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            verifyEmailResp: action.payload,
            error: null
        }
    case VERIFY_EMAIL_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            verifyEmailResp: null
        }
    case FORGOT_PASSWORD_REQUEST:
        return { 
            ...state,
            loading: true,
            forgotPassword: null
        }
    case FORGOT_PASSWORD_SUCCESS:
        return {
            ...state,
            loading: false,
            forgotPassword: action.payload,
            error: null
        }
    case FORGOT_PASSWORD_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            forgotPassword: null
        }
    case RESET_PASSWORD_REQUEST:
        return { 
            ...state,
            loading: true,
            resetPassword: null
        }
    case RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            loading: false,
            resetPassword: action.payload,
            error: null
        }
    case RESET_PASSWORD_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            resetPassword: null
        }
    
    case GET_USER_INVITE_REQUEST:
        return { 
            ...state,
            loading: true,
            userInvite: null
        }
    case GET_USER_INVITE_SUCCESS:
        return {
            ...state,
            loading: false,
            userInvite: action.payload,
            error: null
        }

    case GET_USER_INVITE_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            userInvite: null
        }
    
    case RESEND_VERIFY_EMAIL_REQUEST:
        return { 
            ...state,
            loading: true,
            resendVerifyEmailResp: null,
            error: null,
        }

    case RESEND_VERIFY_EMAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            resendVerifyEmailResp: action.payload,
            error: null
        }
    case CHECK_POST_TITLE_REQUEST:
        return { 
            ...state,
            loading: true,
            error: null,
            titleCheck: null,
        }

    case RESEND_VERIFY_EMAIL_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            resendVerifyEmailResp: null
        }
    
    default:
        return state
  }
}

export default authReducer