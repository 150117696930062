import apiClient from './api-client';

export const getPosts = (params) => {
    return apiClient.get('v1/posts/feed', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const searchPosts = (params) => {
    return apiClient.get('v1/posts/search', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getPostStats = (params) => {
    return apiClient.get('v1/posts/stats', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getUserPosts = (params) => {
    return apiClient.get('v1/posts/me', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getActivityWisePosts = (params) => {
    return apiClient.get('v1/posts/me/activity', {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getPost = (id) => {
    return apiClient.get(`v1/posts/${id}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const getPostBySlug = (slug) => {
    return apiClient.get(`v1/posts/s/${slug}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const createActivity = (payload) => {
    const { id, data } = payload;
    return apiClient.post(`v1/posts/${id}/activity`, data)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const createPost = (payload) => {
    return apiClient.post(`v1/posts/`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const updatePost = (payload) => {
    const { id, data } = payload;
    return apiClient.put(`v1/posts/${id}`, data)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const deletePost = (id) => {
    return apiClient.delete(`v1/posts/${id}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const createPostComment = (id, payload) => {
    return apiClient.post(`v1/posts/${id}/comments`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const fetchPostComments = (id, params) => {
    return apiClient.get(`v1/posts/${id}/comments`, {params})
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const deletePostComment = (id, commentId) => {
    return apiClient.delete(`v1/posts/${id}/comments/${commentId}`)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}

export const checkPostTitle = (payload) => {
    return apiClient.post(`v1/posts/check-title`, payload)
        .then( response => {
            if(response){
                return response.data
            }
            return false
        })
}