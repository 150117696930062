import { 
    GET_PAGE_BY_SLUG_REQUEST,
    GET_PAGE_BY_SLUG_SUCCESS,
    GET_PAGE_BY_SLUG_FAILURE
} from './types';


const initialState = {
  items: [],
  item: null,
  loading: false,
  errors: null
}

const customPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGE_BY_SLUG_REQUEST:
        return {
            ...state,
            loading: true,
            error: null,
            item: null
        }
    case GET_PAGE_BY_SLUG_SUCCESS:
        return {
            ...state,
            loading: false,
            item: action.payload,
            error: null
        }
    case GET_PAGE_BY_SLUG_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            item: null
        }
    default:
        return state
  }
}

export default customPageReducer