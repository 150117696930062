import axios from 'axios'
import store from 'store'

// import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  // timeout: 1000,
  // headers: { 'X-iTree-Client': 'v1' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('authToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})
/*
apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data && data.error) {
    console.log('ERRRR', data)
    
    notification.warning({
      message: data,
    })
    
  }
})
*/

export default apiClient