import React from 'react';
import Loader from 'react-loader-spinner';

const SiteLoader = ({
    isLoading
}) => {
    if(!isLoading){
        return (null)
    }
    return (
        <div className="site-loader">
            <Loader type="Circles" color="#EF752F" height={80} width={80} />
        </div>
    )
}

export default SiteLoader;