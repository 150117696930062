export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST"
export const AUTH_LOGIN_REQUEST_STORY = "AUTH_LOGIN_REQUEST_STORY"
export const AUTH_LOGIN_REQUEST_EVENTS = "AUTH_LOGIN_REQUEST_EVENTS"
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS"
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE"

export const AUTH_LINKEDIN_REQUEST_EVENTLOG = "AUTH_LINKEDIN_REQUEST_EVENTLOG"

export const AUTH_LINKEDIN_REQUEST = "AUTH_LINKEDIN_REQUEST"
export const AUTH_LINKEDIN_SUCCESS = "AUTH_LINKEDIN_SUCCESS"
export const AUTH_LINKEDIN_FAILURE = "AUTH_LINKEDIN_FAILURE"

export const AUTH_GOOGLE_REQUEST = "AUTH_GOOGLE_REQUEST"
export const AUTH_GOOGLE_SUCCESS = "AUTH_GOOGLE_SUCCESS"
export const AUTH_GOOGLE_FAILURE = "AUTH_GOOGLE_FAILURE"

export const AUTH_GOOGLE_REQUEST_EVENTREG = "AUTH_GOOGLE_REQUEST_EVENTREG"

export const REGISTER_USER_REQUEST_FOR_STORYTELLING = "REGISTER_USER_REQUEST_FOR_STORYTELLING"
export const REGISTER_USER_REQUEST_FOR_SOCIALUSER = "REGISTER_USER_REQUEST_FOR_SOCIALUSER"
export const REGISTER_USER_REQUEST_FOR_EVENTUSER = "REGISTER_USER_REQUEST_FOR_EVENTUSER"
export const SUBMIT_STORY_FROM_USER = "SUBMIT_STORY_FROM_USER"
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE"

export const GET_AUTH_ACCOUNT_REQUEST = "GET_AUTH_ACCOUNT_REQUEST"
export const GET_AUTH_ACCOUNT_SUCCESS = "GET_AUTH_ACCOUNT_SUCCESS"
export const GET_AUTH_ACCOUNT_FAILURE = "GET_AUTH_ACCOUNT_FAILURE"

export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const SET_STATE = "AUTH_SET_STATE"

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST"
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS"
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE"

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE"

export const GET_USER_INVITE_REQUEST = "GET_USER_INVITE_REQUEST"
export const GET_USER_INVITE_SUCCESS = "GET_USER_INVITE_SUCCESS"
export const GET_USER_INVITE_FAILURE = "GET_USER_INVITE_FAILURE"

export const RESEND_VERIFY_EMAIL_REQUEST = "RESEND_VERIFY_EMAIL_REQUEST"
export const RESEND_VERIFY_EMAIL_SUCCESS = "RESEND_VERIFY_EMAIL_SUCCESS"
export const RESEND_VERIFY_EMAIL_FAILURE = "RESEND_VERIFY_EMAIL_FAILURE"

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST"
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS"
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE"

export const CHECK_POST_TITLE_REQUEST = "CHECK_POST_TITLE_REQUEST"
export const CHECK_POST_TITLE_SUCCESS = "CHECK_POST_TITLE_SUCCESS"
export const CHECK_POST_TITLE_FAILURE = "CHECK_POST_TITLE_FAILURE"