import { 
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_REQUEST_STORY,
    AUTH_LOGIN_REQUEST_EVENTS,
    AUTH_LOGOUT,
    AUTH_LINKEDIN_REQUEST,
    AUTH_LINKEDIN_REQUEST_EVENTLOG,
    AUTH_GOOGLE_REQUEST,
    AUTH_GOOGLE_REQUEST_EVENTREG,
    REGISTER_USER_REQUEST,
    REGISTER_USER_REQUEST_FOR_STORYTELLING,
    REGISTER_USER_REQUEST_FOR_SOCIALUSER,
    REGISTER_USER_REQUEST_FOR_EVENTUSER,
    VERIFY_EMAIL_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    GET_USER_INVITE_REQUEST,
    GET_AUTH_ACCOUNT_REQUEST,
    SUBMIT_STORY_FROM_USER,
    CHECK_POST_TITLE_REQUEST,
    RESEND_VERIFY_EMAIL_REQUEST
} from './types';

export const SubmitStorydata = (data) => {
    return {
        type: SUBMIT_STORY_FROM_USER,
        payload: data
    }
}

export const createEventUser = (data) => {
    return {
        type: REGISTER_USER_REQUEST_FOR_EVENTUSER,
        payload: data
    }
}

export const createSocialUser = (data) => {
    return {
        type: REGISTER_USER_REQUEST_FOR_SOCIALUSER,
        payload: data
    }
}

export const createStorytellingUser = (data) => {
    return {
        type: REGISTER_USER_REQUEST_FOR_STORYTELLING,
        payload: data
    }
}

export const createUser = (data) => {
    return {
        type: REGISTER_USER_REQUEST,
        payload: data
    }
}

export const loginUser = (data) => {
    return {
        type: AUTH_LOGIN_REQUEST,
        payload: data
    }
}

export const loginUserStory = (data) => {
    return {
        type: AUTH_LOGIN_REQUEST_STORY,
        payload: data
    }
}

export const loginUserEventStory = (data) => {
    return {
        type: AUTH_LOGIN_REQUEST_EVENTS,
        payload: data
    }
}

export const linkedInUser = (data) => {
    return {
        type: AUTH_LINKEDIN_REQUEST,
        payload: data
    }
}

export const linkedInUserEventLog = (data) => {
    return {
        type: AUTH_LINKEDIN_REQUEST_EVENTLOG,
        payload: data
    }
}

export const googleLoginUser = (data) => {
    return {
        type: AUTH_GOOGLE_REQUEST,
        payload: data
    }
}

export const googleLoginUser_EventReg = (data) => {
    return {
        type: AUTH_GOOGLE_REQUEST_EVENTREG,
        payload: data
    }
}

export const verifyEmail = (data) => {
    return {
        type: VERIFY_EMAIL_REQUEST,
        payload: data
    }
}

export const logoutUser = () => {
    return {
        type: AUTH_LOGOUT
    }
}

export const forgotPassword = (data) => {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        payload: data
    }
}

export const resetPassword = (data) => {
    return {
        type: RESET_PASSWORD_REQUEST,
        payload: data
    }
}

export const getUserInvite = (id, params) => {
    return {
        type: GET_USER_INVITE_REQUEST,
        payload: { id, params }
    }
}

export const getMeUser = () => {
    return {
        type: GET_AUTH_ACCOUNT_REQUEST
    }
}

export const resendEmailVerification = (data) => {
    return {
        type: RESEND_VERIFY_EMAIL_REQUEST,
        payload: data
    }
}

export const checkPostTitle = (data) => {
    return {
        type: CHECK_POST_TITLE_REQUEST,
        payload: data
    }
}