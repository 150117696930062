import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FaChevronDown, FaRegUserCircle, FaChevronRight } from 'react-icons/fa';
import { AiOutlineMenu, AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import { connect, useDispatch } from 'react-redux'

import { useComponentVisible } from '../utils/hooks';
import { resendEmailVerification } from '../redux/auth/actions';
import { resetLocalPost } from '../redux/post/actions';
import { POST_TYPES, getStaticUrl, getInitials, getUserBadge, getUserBadgeMatrix3 } from '../utils';
import GlobalSearchModal from './GlobalSearchModal'

/**
 * 
 * Not SIGNIN -> ONBOARD
 * SIGNIN AND READER ACCESS -> COLLAB FORM
 * SIGNIN AND WRITER -> WRITE
 * 
 * 
 */

const ABOUT_MENU = [
  {
    to: '/about',
    label: 'What is Imagine Tree'
  },/*
  {
    to: '/the-team',
    label: 'The People Behind Imagine Tree'
  },
  {
    to: '/gallery',
    label: 'Gallery'
  },*/
  {
    to: '/frequently-asked-questions',
    label: 'FAQ'
  },
  {
    to: '/community',
    label: 'Community'
  },
];

const CONTRIBUTE_MENU = [
  {
    to: '/share-a-story',
    label: 'Share A Story'
  },
  /*
  {
    to: '/write-a-book-review',
    label: 'Write A Book Review'
  },
  {
    to: '/feedback',
    label: 'Submit Feedback'
  },
  */
  {
    to: '/collaborate-with-us',
    label: 'Collaborate With Us'
  },
];
/*
const STORIES_MENU = [
  {
    to: '/stories/books',
    label: 'Books'
  },
  {
    to: '/stories/blogs',
    label: 'Blogs'
  },
  {
    to: '/stories/podcasts',
    label: 'Podcasts'
  },
  {
    to: '/stories/videos',
    label: 'Videos'
  },
  {
    to: '/stories/trivia',
    label: 'Trivia'
  },
  {
    to: '/stories/infographics',
    label: 'Infographics'
  },
  {
    to: '/stories/data-viz',
    label: 'Data Viz'
  },
];
*/
const EVENTS_MENU = [
  {
    to: '/event/stories-unbound',
    label: 'Stories Unbound \'20'
  },
  /*{
    to: '/events/books',
    label: 'Books'
  },
  {
    to: '/events/blogs',
    label: 'Blogs'
  },
  {
    to: '/events/podcasts',
    label: 'Podcasts'
  },
  {
    to: '/events/videos',
    label: 'Videos'
  },
  {
    to: '/events/trivia',
    label: 'Trivia'
  },
  {
    to: '/events/infographics',
    label: 'Infographics'
  },
  {
    to: '/events/data-viz',
    label: 'Data Viz'
  },*/
];

const NavMenu = ({ children, dropdown, isActive }) => {
  const cssClasses = [];
  if(dropdown){
    cssClasses.push('dropdown-menu')
  }else{
    cssClasses.push('menu')
  }

  if(isActive){
    cssClasses.push('active')
  }
  return (
    <ul className={ cssClasses.join(' ') }>
      {children}
    </ul>
  )
}

const NavItem = ({ children, onClick }) => {
  return (
    <li onClick={onClick}>
      {children}
    </li>
  )
}

const Navbar = ({
  isAuthenticated,
  hideLogNave,
  user,
  history: { location: { pathname } },
  showGlobalSearch
}) => {
  const dispatch = useDispatch()
  const [click, setClick] = useState(false);
  const [ selectedDropdown, setSelectedDropdown ] = useState(null);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  //console.log(isAuthenticated, user)

  useEffect(() => {
    closeMobileMenu();
    setSelectedDropdown(null)
  }, [pathname, setSelectedDropdown]);

  const onMenuSelect = (menu) => {
    setSelectedDropdown(menu)
    setIsComponentVisible(true)
  }

  let userPic = user && getStaticUrl(user.image);
  const userBadge = getUserBadge((user && user.profile && user.profile.earnedPoints) || 0)
  const badgeV2 = getUserBadgeMatrix3((user && user.badges) || {})

  const handleGlobalSearch = (isShowing) => {
    dispatch({type: 'AUTH_SET_STATE', payload: {showGlobalSearch: isShowing}})
  }

  let completedPercentages = 0;

  if(user && user.profile && user.profile.earnedPoints>0){
    completedPercentages = (user.profile.earnedPoints/1000) * 100
  }

  //console.log('userBadge', userBadge, completedPercentages + '%')
  const excludedRoute = RegExp('(/auth/verify-email|/account/on-boarding/)').test(pathname)
  //console.log('pathname', excludedRoute)
  const disnavlogpage = ["event-login","storytelling", "event-registration", "social-registration","storytelling-login", "share-story-thankyou", "share-your-story","campaign-signup"];
  const path = window.location.pathname;
  const page = path.split("/").pop();

  if(disnavlogpage.indexOf(page) == -1) {
      hideLogNave = {};
  }
  else {
      hideLogNave = {
            display:'none'
      };
  }
  
  return (
    <>
      <IconContext.Provider value={{ color: '#333' }}>
        <nav className="it-navbar">
          <div className="it-navbar-container">
            <div className="it-navbar-left">
              <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                <img src={`/main_logo.svg`} alt="Imagine Tree" />
              </Link>
            </div>
            <div className={ click ? "it-navbar-center active": "it-navbar-center"} ref={ref} style={hideLogNave}>
              <NavMenu isActive={click}>
              
                <NavItem>
                  <Link to="/events">Events</Link>
                </NavItem>
                <NavItem onClick={() => { onMenuSelect('stories') }}>
                  Stories &nbsp;
                  <FaChevronDown style={{marginTop: -4, fontSize: 16}}/>
                  {
                    (selectedDropdown === "stories" && isComponentVisible) &&
                    <NavMenu dropdown>
                      {
                        POST_TYPES.map(({id, name, slug}) => (
                        <NavItem key={id}>
                          <Link to={`/stories/${slug}`}><span>{name}</span><FaChevronRight /></Link>
                        </NavItem>))
                      }
                    </NavMenu>
                  }
                </NavItem>
                <NavItem onClick={() => { onMenuSelect('contribute') }}>
                  Contribute &nbsp;
                  <FaChevronDown style={{marginTop: -4, fontSize: 16}}/>
                  {
                    (selectedDropdown === "contribute" && isComponentVisible) &&
                    <NavMenu dropdown>
                      {
                        CONTRIBUTE_MENU.map(({to, label}) => (
                        <NavItem key={to}>
                          <Link to={to}><span>{label}</span><FaChevronRight /></Link>
                        </NavItem>))
                      }
                    </NavMenu>
                  }
                </NavItem>
                
                <NavItem onClick={() => { onMenuSelect('about') }}>
                  About &nbsp;
                  <FaChevronDown style={{marginTop: -4, fontSize: 16}}/>
                  {
                    (selectedDropdown === "about" && isComponentVisible) &&
                    <NavMenu dropdown>
                      {
                        ABOUT_MENU.map(({to, label}) => (
                        <NavItem key={to}>
                          <Link to={to}><span>{label}</span><FaChevronRight /></Link>
                        </NavItem>))
                      }
                    </NavMenu>
                  }
                </NavItem>
                <NavItem>
                  <Link to="/contact-us">Contact</Link>
                </NavItem>
                <NavItem onClick={() => handleGlobalSearch(true)}>
                  <AiOutlineSearch style={{width: 24, height: 24}} />
                </NavItem>
              </NavMenu>
            </div>
            <div className="it-navbar-right">
              <div className='menu-icon' onClick={handleClick}>
                {click ? <AiOutlineClose /> : <AiOutlineMenu />}
              </div>
              <div className="it-navbar-auth" style={hideLogNave}>
                {
                  isAuthenticated && user &&
                  <>
                    {
                      /**
                       * 
                       * <Link to="/write/new-story" className="btn-it-primary" onClick={() => dispatch(resetLocalPost())}>Publish Story</Link>
                       */
                    }
                    {
                      user && user.role !== 'basic' && 
                      <Link to="/write/new-story" className="btn-it-primary" onClick={() => dispatch(resetLocalPost())}>Publish Story</Link>
                    }
                    <div className="user-menu-box">
                      {
                        userPic && <img className="user-logo" src={userPic} alt={user && user.name} onClick={() => { onMenuSelect('userMenu') }} />
                      }
                      {
                        !userPic && <div className="user-logo" onClick={() => { onMenuSelect('userMenu') }}>{getInitials(user.name)}</div>
                      }
                      <div className={selectedDropdown === "userMenu"  && isComponentVisible ? 'user-menu-container active': 'user-menu-container'}>
                        <ul className="user-menu">
                          <li className="user-details with-divider">
                            {
                              userPic && <img className="user-logo" src={userPic} alt={user && user.name}/>
                            }
                            {
                              !userPic && <div className="user-logo">{getInitials(user.name)}</div>
                            }
                            <h5>{user && user.name}</h5>
                          </li>
                          {
                            user &&
                            <li className="user-points-item">
                              <Link to='/account/posts'>
                              <div className="user-points">
                                <img className="badge-icon" src={`/assets/images/badges/${userBadge.icon_prefix}${badgeV2.badgeIconCode}.png`} alt="/assets/images/badges/legend_3.png" />
                                <div className="point-progress-bar">
                                  <div className="point-labels"><span>{badgeV2.title}</span> <span>{(user.profile && user.profile.earnedPoints) || 0} Points</span></div>
                                  <div className="progress-bar">
                                    <div className="active-progres" style={{width: `${completedPercentages}%`, backgroundColor: `${userBadge.color}`}} />
                                  </div>
                                </div>
                              </div>
                              </Link>
                            </li>
                          }
                          {
                            user && user.role !== "basic" &&
                            <li>
                              <Link to="/write/new-story">Write Story</Link>
                            </li>
                          }
                          
                          <li>
                            <Link to="/feed">My Feed</Link>
                          </li>
                          <li>
                            <Link to="/account/posts">My Journey</Link>
                          </li>
                          <li>
                            <Link to="/account/profile/edit">Profile</Link>
                          </li>
                          <li>
                            <Link to="/logout">Sign out</Link>
                          </li>
                        </ul>
                        <div className="top-pointer"></div>
                      </div>
                    </div>
                  </>
                }
                {
                  !isAuthenticated &&
                  <>
                    <div className="navlogwapper-p" style={hideLogNave}>
                    <Link to="/auth/login" className="btn-it-link"><FaRegUserCircle /> &nbsp;&nbsp; Login</Link>
                    <Link to='/auth/signup' className='btn-it-primary'>
                        Join Now
                    </Link>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </nav>
        {
          user && !excludedRoute && !user.isEmailVerified &&
          <div className="alert alert-warning text-center rounded-0 mb-0" style={{fontWeight: 200}}>Your email address is not verified yet, please check your inbox for verification email <b>OR</b> <Link onClick={() => dispatch(resendEmailVerification({email: user.email}))}>Click here</Link> to resend Verification Email.</div>
        }
      </IconContext.Provider>
      {
        showGlobalSearch && <GlobalSearchModal onClose={() => handleGlobalSearch(false)} />
      }
    </>
  );
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.authorized,
  user: auth.user,
  showGlobalSearch: auth.showGlobalSearch,
})

export default withRouter(connect(mapStateToProps)(Navbar));